import React, { useState,useEffect } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import { toAbsoluteUrl } from '../../_helpers';
import { FaExternalLinkAlt,FaInfoCircle,FaLongArrowAltUp,FaLongArrowAltDown} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
// import {GiCutDiamond} from "react-icons/gi"
import SVG from "react-inlinesvg";
import {Modal,Button} from "react-bootstrap";
import axios from 'axios';
import VideoIframe from '../detailPage/VideoIframe';
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import { MainImagePopup } from '../widgets/tiles/MainImagePopUp';
import { IVPCURRENCY } from '../../../env_config';
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from '../Popup';
import { ImageExpandPopupCommon } from '../widgets/tiles/ImageExpandPopupCommon';
import { VideoExpandPopupCommon } from '../widgets/tiles/VideoExpandPopupCommon';
import { MainImageExpandedDetailsPopup } from '../widgets/tiles/MainImageExpandedDetailsPopup';
import CertificateLink from '../CertificateLink';

export default function ExpandedDetails({row,open,conversionrate,symbol,language,BuyDiamond,HoldDiamond,symbolname,userinfo}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast] = useState(false)
    const [wishlistmessage] = useState(null)
    const [currentCurrRate,setCurrentCurrRate] = useState("1")
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.lab){
        //     title += row.lab
        // }
        if(row.shape){
            title += " "
            title += row.shape
        }
        if(row.carat){
            title += " "
            title += row.carat.toFixed(2) 
        }
        // if(row.fancy_intensity){
        //     title += " "
        //     title += row.fancy_intensity
        // }
        if(row.color){
            title += " "
            title += row.color
        }
        if(row.clarity){
            title += " "
            title += row.clarity
        }

        if(row.cut){
            title += " "
            title += row.cut
        }
       
        if(row.polish){
            title += " "
            title += row.polish
        }

        if(row.symmetry){
            title += " "
            title += row.symmetry
        }

        if(row.fluorescence){
            title += " "
            title += row.fluorescence
        }
        return title
    }
    // const RouteToDetail = (data) => {
    //     history.push({
    //         pathname: `/detail/${row.diamondType}-${row.ProductId}`
    //         });
    // }
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }

    const shadeMappings = {
        "none": { label: "No.SH", tooltip: "Shade: NONE" },
        "brown": { label: "BR", tooltip: "Shade: Brown" },
        "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
        "green": { label: "GR", tooltip: "Shade: Green" },
        "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
        "grey": { label: "GREY", tooltip: "Shade: Grey" },
        "black": { label: "BLK", tooltip: "Shade: Black" },
        "pink": { label: "PINK", tooltip: "Shade: Pink" },
        "blue": { label: "BLUE", tooltip: "Shade: Blue" },
        "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
    };
    
    const renderShadeLabel = (shade) => {
        const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
        if (!mapping) return null;
    
        return (
            <span>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
                >
                    <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
                </OverlayTrigger>
            </span>
        );
    };

    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    useEffect(() => {
        let curr = "cur_inr"
        if(row.currency === "INR"){
          curr = "cur_inr"
        }
        if(row.currency === "USD"){
          curr = "cur_usd"
        }
        if(row.currency === "AUD"){
          curr = "cur_aud"
        }
        if(row.currency === "CAD"){
          curr = "cur_cad"
        }
        if(row.currency === "HKD"){
          curr = "cur_hkd"
        }
        if(row.currency === "CNY"){
          curr = "cur_cny"
        }
        if(row.currency === "EUR"){
          curr = "cur_eur"
        }
        if(row.currency === "GBP"){
          curr = "cur_gbp"
        }
        if(row.currency === "NZD"){
          curr = "cur_nzd"
        }
        if(row.currency === "JPY"){
          curr = "cur_jpy" 
        }
        if(row.currency === "CHF"){
          curr = "cur_chf"
        }
        if(row.currency === "BND"){
            curr = "cur_bnd"
        }
        if(row.currency === "SGD"){
            curr = "cur_sgd"
        }
        if(row.currency === "THB"){
            curr = "cur_thb"
        }
        if(row.currency === "MYR"){
            curr = "cur_myr"
        }
        if(row.currency === "DKK"){
            curr = "cur_dkk"
        }
        if(row.currency === "SEK"){
            curr = "cur_sek"
        }
        axios({
            method: "POST",
            url: IVPCURRENCY,
            headers: { "Content-Type": "application/json" },
        }).then(response => {
            if(response && response.data){
                // console.log(response.data.data[0])
                response.data.data[0].cur_usd = 1
                const currentCurrency = response.data.data[0][curr];
                setCurrentCurrRate(Math.round(response.data.data[0][curr]*100)/100)
                // console.log(curr,currentCurrency)
            }
        })
    },[row.currency])

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }

    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }


    function numberWithCommas(x) {
        if(!x){
            return "0.00"
        }
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const getshortlistedsymbol = (responsecurrency) => {
        let symbol = "$"
        if(responsecurrency === "INR"){
          symbol = "₹"
        }
        if(responsecurrency === "USD"){
          symbol = "$"
        }
        if(responsecurrency === "AUD"){
          symbol = "$"
        }
        if(responsecurrency === "CAD"){
          symbol = "$"
        }
        if(responsecurrency === "HKD"){
          symbol = "$"
        }
        if(responsecurrency === "CNY"){
          symbol = "¥"
        }
        if(responsecurrency === "EUR"){
          symbol = "€"
        }
        if(responsecurrency === "GBP"){
          symbol = "£"
        }
        if(responsecurrency === "NZD"){
          symbol = "$"
        }
        if(responsecurrency === "JPY"){
          symbol = "¥" 
        }
        if(responsecurrency === "CHF"){
          symbol = "₣"
        }
        if(responsecurrency === "SGD"){
            symbol = "$"
            
        } if(responsecurrency === "THB"){
            symbol = "฿"
            
        } if(responsecurrency === "MYR"){
            symbol = "RM"
            
        } if(responsecurrency === "BND"){
            symbol = "$"
            
        } if(responsecurrency === "DKK"){
            symbol = "kr."
            
        } if(responsecurrency === "SEK"){
            symbol = "kr"
            
        }
        return symbol
      }
    return(
       <>
        <div className={`expandedDetails natTab ${row.isfancy ? "fancyExpand" : "" }`} >
            <div className="row p-5">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                    <ImageExpandPopupCommon data={row}/>
                    <VideoExpandPopupCommon  data={row} userinfo={userinfo}/>
                        
                        {row.heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row.arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row.asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4 ribbon ribbon-top">
                        {row.diamondType === "N" || row.diamondType === "W" ? null: <div className="ribbon-target custom-top-ribbon lab-ribbon" style={{top:"-2px",right:"20px"}} >Lab</div> }
                        <MainImageExpandedDetailsPopup data={row}/>
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            <div className="mr-2 border-right  small">
                                {row.lab && userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11" ? <>{row.lab}:</> : ""}{row.Certi_NO &&  userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11" ? (
                                    
                                    <CertificateLink Certificate_link={row.Certi_link} lab={row.Lab} certificate={row.Certi_NO} withExternalLinkIcon  className={`mx-2 text-left`} />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className='small'>
                                {language.MUI_STOCKNO}: 
                                {row.stockID &&  userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11" ?
                                    <a href={`/detail/${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}`} target="_blank" rel="noreferrer" className=" d-inline-block mr-2 cursor-pointer">{`${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}`}</a>
                                :
                                    `${row.diamondType === "N" || row.diamondType === "W"?"N":"L"}-${row.stockID}`
                                }
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h3 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        <div className='col-12 advTag'>
                            {/* shade */}
                            {renderShadeLabel(row.shade)}
         
                            { row.milky && row.milky.toLowerCase() === "no milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                NO MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "luster low" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Luster Low
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "light milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                LIGHT MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            { row.milky && row.milky.toLowerCase() === "milky" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                MILKY
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                                               {/* eyeclean */}
                                               { row.eyeClean && row.eyeClean.toLowerCase() === "yes" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                100% Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.eyeClean && row.eyeClean.toLowerCase() === "no" ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                No Eye Clean
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }

                            {/* growthtype */}
                            { row.diamondType === "L" && (row.growthType && row.growthType === "CVD") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                CVD
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            { row.diamondType === "L" && (row.growthType && row.growthType === "HPHT") ?
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                HPHT
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                                    </OverlayTrigger>
                                </span>
                            :
                                null
                            }
                            
                            { (row.diamondType === "L" ) && (row.treatment && row.treatment.toLowerCase() === "as grown") ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                As Grown
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                            {/* natural treated */}
                            { (row.diamondType === "N" || row.diamondType === "W") && (row.treatment && row.treatment.toLowerCase() !== "none")  ? 
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Treated
                                            </Tooltip>
                                        }
                                    >
                                        <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                                    </OverlayTrigger>
                                </span>
                            : 
                                null
                            }
                        </div>
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${row.listPrice?row.listPrice:"0000"}</span>
                            <span className="font-size-lg text-dark-50 ml-8"><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.CustomerShippingDays}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-6 mt-5">
                            <div className="shortlist-price-box">
                                <h3 className="">
                                   Tracklisted Price
                                </h3>
                                <hr className="w-25 ml-0 my-2"/>
                                <div className='d-flex'>
                                    <div className="text-dark font-size-lg">({ row.currency})</div>
                                    {row.stoneStatus !== 1 ? <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Tracklisted Cost {getshortlistedsymbol(row.currency)}/CT <b>{getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd/row.carat * row.currency_rate)} </b>
                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd * row.currency_rate)}</b> 
                                            </Tooltip>
                                        }
                                    >
                                        <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                    </OverlayTrigger> : null }
                                </div>
                                <span className="h1">
                                    <><span className="small">{getshortlistedsymbol(row.currency)}</span >{numberWithCommas(row.tracklisted_price_usd * row.currency_rate)}</>
                                </span>
                                <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd/row.carat  * row.currency_rate)}</span></span>
                            </div>
                        </div>
                        <div className="col-6 mt-12 d-flex">
                            {row.stoneStatus === 1 ?
                                <div className="p-5">
                                    <h5 className="position-relative ">
                                        Current Cost Price
                                    </h5>
                                    <hr className="w-25 ml-0 my-2"/>
                                    {row.ourDiscount?row.ourDiscount.includes('-')?
                                    <div className="d-flex small text-danger align-items-center">{row.ourDiscount}% Off<span className="ml-8 text-dark font-size-lg">({ row.currency})</span></div>:
                                    <div className="d-flex small text-success align-items-center">{row.ourDiscount}% PR<span className="ml-8 text-dark font-size-lg">({ row.currency})</span></div>
                                    :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark">({ row.currency})</span></div>}
                                    
                                    <div className='d-flex align-items-end'>
                                        <div>
                                            <span className="h4">
                                                <><span className="small">{getshortlistedsymbol(row.currency)}</span >{numberWithCommas(row.ourPrice * currentCurrRate)}</>
                                            </span>
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{getshortlistedsymbol(row.currency)}{numberWithCommas(row.ourRate * currentCurrRate)}</span></span>
                                        </div>
                                        <div>
                                            { 
                                                row.tracklisted_price_usd * row.currency_rate === row.ourPrice * currentCurrRate
                                                ? ""
                                                : row.tracklisted_price_usd * row.currency_rate < row.ourPrice * currentCurrRate
                                                ?
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Tracklisted Cost {getshortlistedsymbol(row.currency)}/CT <b>{getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd/row.carat * row.currency_rate)} </b>
                                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd * row.currency_rate)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltUp className='text-danger cursor-pointer font-size-h3' /></span>
                                                    </OverlayTrigger>
                                                :
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Tracklisted Cost {getshortlistedsymbol(row.currency)}/CT <b>{getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd/row.carat * row.currency_rate)} </b>
                                                                <br/>Tracklisted Cost price<b> {getshortlistedsymbol(row.currency)}{numberWithCommas(row.tracklisted_price_usd * row.currency_rate)}</b> 
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <span><FaLongArrowAltDown className='text-success cursor-pointer  font-size-h3' /></span>
                                                    </OverlayTrigger>
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null }  
                        </div>
                        <div className="col-12 mt-5">
                            <button type="button" className="btn btn-primary px-8 mr-3" onClick={() => (userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11") ? BuyDiamond([{original:row}]) :  DetailSemiAprrove() }><BsLightningFill /> {language.MUI_BUYDIAMONDBTN}</button>
                            <button type="button" className="btn btn-light" onClick={() => (userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11") ? HoldDiamond([{original:row}]) :  DetailSemiAprrove() }> 
                                <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Hold.svg")} width="22.384" height="20.075" />
                                {language.MUI_HOLDDIAMONDBTN}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.isfancy) ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_intensity ? row.fancy_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_overtone ? row.fancy_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row.fancy_color ? row.fancy_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bold mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 font-weight-bold"> {row.length} * {row.width} * {row.height} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 font-weight-bold"> {row.tablePercent ? row.tablePercent : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 font-weight-bold"> {row.depthPercent ? row.depthPercent : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.crownHeight ? row.crownHeight : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.crownAngle ? row.crownAngle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.pavilionHeight ? row.pavilionHeight : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.pavilionAngle ? row.pavilionAngle : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.ratio?row.ratio.toFixed(2):"-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.girdle ? row.girdle : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.girdleCondition  ? row.girdleCondition : "-"}
                                </div>
                            </div>
                            
                        </div>
                        {(userinfo?.v_customer?.toString() !=="7" && userinfo?.v_customer?.toString() !=="11") ? (
                            <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bold mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.brand ? row.brand : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.milky  ? row.milky : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.eyeClean ? row.eyeClean : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 font-weight-bold"> {row.culet  ? row.culet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 font-weight-bold">{row.keyToSymbol ? row.keyToSymbol : "-" }</div>
                                </div>
                                    {row.diamondType === "L" ? 
                                        <>
                                            { (row.growthType) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 font-weight-bold">  {row.growthType ? row.growthType : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment} </div>
                                                </div>
                                            ):(row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment ? row.treatment : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.origin) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 font-weight-bold">  {row.origin ? row.origin : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.brand) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brand ? row.brand : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.treatment) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.treatment ? row.treatment : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                            ) : ("")}
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.heart} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.arrow} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.asset} alt={row.shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.diamondType === "N" || row.diamondType === "W" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}