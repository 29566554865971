import React ,{useEffect, useState}from "react";
import { Grid, Typography, InputLabel,TextField } from "@material-ui/core";
import { InputField, CheckboxField, SelectField, PhoneInput } from "../FormFields";
import { at } from 'lodash';
import { useField } from 'formik';
const cities = [
    {
        value: undefined,
        label: "None",
    },
    {
        value: "1",
        label: "New York",
    },
    {
        value: "2",
        label: "Chicago",
    },
    {
        value: "3",
        label: "Saigon",
    },
];

const states = [
    {
        value: undefined,
        label: "None",
    },
    {
        value: "11",
        label: "Florida",
    },
    {
        value: "22",
        label: "Michigan",
    },
    {
        value: "33",
        label: "Texas",
    },
];

const countries = [
    {
        value: null,
        label: "None",
    },
    {
        value: "111",
        label: "United States",
    },
    {
        value: "222",
        label: "Italy",
    },
    {
        value: "333",
        label: "Vietnam",
    },
];
const shipping_state_data = [
    {
        value: 'Andhra Pradesh',
        label: 'Andhra Pradesh'
      },
      {
        value: 'Arunachal Pradesh',
        label: 'Arunachal Pradesh'
      },
      {
        value: 'Assam',
        label: 'Assam'
      },
      {
        value: 'Bihar',
        label: 'Bihar'
      },
      {
        value: 'Chhattisgarh',
        label: 'Chhattisgarh'
      },
      {
        value: 'Goa',
        label: 'Goa'
      },
      {
        value: 'Gujarat',
        label: 'Gujarat'
      },
      {
        value: 'Haryana',
        label: 'Haryana'
      },
      {
        value: 'Himachal Pradesh',
        label: 'Himachal Pradesh'
      },
      {
        value: 'Jammu & Kashmir',
        label: 'Jammu & Kashmir'
      },
      {
          value: 'Jharkhand',
          label: 'Jharkhand'
      },
      {
          value: 'Karnataka',
          label: 'Karnataka'
      },
      {
        value: 'Kerala',
        label: 'Kerala'
      },
      {
        value: 'Madhya Pradesh',
        label: 'Madhya Pradesh'
      },
      {
          value: 'Maharashtra',
          label: 'Maharashtra'
      },
      {
          value: 'Manipur',
          label: 'Manipur'
      },
      {
          value: 'Meghalaya',
          label: 'Meghalaya'
      },
      {
          value: 'Mizoram',
          label: 'Mizoram'
      },
      {
          value: 'Nagaland',
          label: 'Nagaland'
      },
      {
        value: 'Orissa',
        label: 'Orissa'
      },
      {
        value: 'Punjab',
        label: 'Punjab'
      },
      {
        value: 'Rajasthan',
        label: 'Rajasthan'
      },
      {
        value: 'Sikkim',
        label: 'Sikkim'
      },
      {
        value: 'Tamil Nadu',
        label: 'Tamil Nadu'
      },
      {
        value: 'Telengana',
        label: 'Telengana'
      },
      {
        value: 'Tirupati',
        label: 'Tirupati'
      },
      {
        value: 'Uttar Pradesh',
        label: 'Uttar Pradesh'
      },
      {
        value: 'Uttarakhand',
        label: 'Uttarakhand'
      },
      {
        value: 'West Bengal',
        label: 'West Bengal'
      }
  ];
export default function ShippingInfoForm(props) {
    const [field, meta] = useField("shipping_address1");

    function _renderHelperText() {
      const [touched, error] = at(meta, 'touched', 'error');
      if (touched && error) {
        return error;
      }
    }
    const {
        formField: {
            shipping_address1,
            shipping_address2,
            shipping_address3,
            shipping_city,
            shipping_state,
            shipping_zipcode,
            shipping_country,
            shipping_telephone,
            shipping_email,
            atten_name,
            port_of_discharge,
            same_as_shipping_address,
        },
        values,
        setFieldValue
    } = props;
    const [select,setSelect] = useState(false)
    const SameAsComp = () => {
        let atten_name = values.firstName + " " + values.lastName
        setFieldValue("atten_name",atten_name)
        setFieldValue("shipping_email",values.email)
        setFieldValue("port_of_discharge",values.country)
        if(values.address1){
            setFieldValue("shipping_address1",values.address1)
        }
        if(values.address2){
            setFieldValue("shipping_address2",values.address2)
        }
        if(values.address3){
            setFieldValue("shipping_address3",values.address3)
        }
        if(values.city){
            setFieldValue("shipping_city",values.city)
        }
        if(values.state){
            setFieldValue("shipping_state",values.state)
        }
        if(values.zipcode){
            setFieldValue("shipping_zipcode",values.zipcode)
        }
        if(values.country){
            setFieldValue("shipping_country",values.country)
        }
    }
    useEffect(() => {
        if(select){
            
            SameAsComp()
        }
    },[select])
    return (
        <React.Fragment>
            <Typography className="mb-10" variant="h4" align="center">
                Shipping Address
            </Typography>
            <Grid container justify="center" alignItems="center" spacing={3}>
                <Grid item xs={7}>
                    <CheckboxField
                        name={same_as_shipping_address.name}
                        label={props.language.PROFILE_SAME_AS}
                        onClick={() => setSelect(!select)}
                    />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={12}>
                            <InputField
                                name={atten_name.name}
                                label={props.language.PROFILE_SHIPPING_ATTEN_NAME}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={shipping_address1.name}
                                label={props.language.PROFILE_SHIPPING_COMPANY_ADDRESS1}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={shipping_address2.name}
                                label={props.language.PROFILE_SHIPPING_COMPANY_ADDRESS2}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={shipping_address3.name}
                                label={props.language.PROFILE_SHIPPING_COMPANY_ADDRESS3}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={shipping_city.name}
                                label={props.language.PROFILE_SHIPPING_CITY}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <SelectField
                                name={shipping_state.name}
                                label={props.language.PROFILE_SHIPPING_STATE}
                                data={shipping_state_data}
                                fullWidth
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <InputField
                                name={shipping_state.name}
                                label={props.language.PROFILE_SHIPPING_STATE}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField
                                name={shipping_zipcode.name}
                                label={props.language.PROFILE_SHIPPING_ZIP}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                name={shipping_country.name}
                                label={props.language.PROFILE_SHIPPING_COUNTRY}
                                fullWidth
                                disabled={true}
                                InputLabelProps={{
                                    shrink: true, // Ensures the label doesn't overlap with the value
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={7}>
                    {/* <InputField
                        name={shipping_telephone.name}
                        label={shipping_telephone.label}
                        fullWidth
                    /> */}
                    {/* <InputLabel className="font-size-h6 font-weight-bold">
                    {props.language.PROFILE_SHIPPING_TELEPHONE}
                    </InputLabel> */}
                    <PhoneInput name={shipping_telephone.name} label={props.language.PROFILE_SHIPPING_TELEPHONE} fullWidth onChange={(value) => setFieldValue("shipping_telephone",value)}/>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={shipping_email.name}
                        label={props.language.PROFILE_SHIPPING_EMAIL}
                        fullWidth
                        InputLabelProps={{
                            shrink: true, // Ensures the label doesn't overlap with the value
                        }}
                    />
                </Grid>
               
                <Grid item xs={12} sm={7}>
                    <InputField
                        name={port_of_discharge.name}
                        label={port_of_discharge.label}
                        fullWidth
                        InputLabelProps={{
                            shrink: true, // Ensures the label doesn't overlap with the value
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
