import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { FaAward } from "react-icons/fa";
import { VideoPopup } from "../../../widgets/tiles/VideoPopUp";
import { ImagePopup } from "../../../widgets/tiles/ImagePopUp";
import { toAbsoluteUrl } from "../../../../_helpers";
import { ConsumerPopup } from "./ConsumerPopup";
import moment from "moment-timezone";
import { LiaAwardSolid } from "react-icons/lia";
import CertificateLink from "../../../CertificateLink";
// import axios from "axios";
// import { NODE_API_URL } from "../../../../../env_config";

function numberWithCommas(x) {
    return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
// function formattedDate(x) {
//     const originalTimestamp = new Date(x);
//     return originalTimestamp
// }

const shadeMappings = {
    "none": { label: "No.SH", tooltip: "Shade: NONE" },
    "brown": { label: "BR", tooltip: "Shade: Brown" },
    "light brown": { label: "L.BR", tooltip: "Shade: Light Brown" },
    "green": { label: "GR", tooltip: "Shade: Green" },
    "light green": { label: "L.GR", tooltip: "Shade: Light Green" },
    "grey": { label: "GREY", tooltip: "Shade: Grey" },
    "black": { label: "BLK", tooltip: "Shade: Black" },
    "pink": { label: "PINK", tooltip: "Shade: Pink" },
    "blue": { label: "BLUE", tooltip: "Shade: Blue" },
    "mix tinge": { label: "MIX", tooltip: "Shade: Mix Tinge" },
};

const renderShadeLabel = (shade) => {
    const mapping = shadeMappings[shade ? shade.toLowerCase() : ""];
    if (!mapping) return null;

    return (
        <span>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip">{mapping.tooltip}</Tooltip>}
            >
                <span className={`label label-light-primary label-light-shade_${shade.toLowerCase().replace(' ', '_')} label-pill label-inline label-sm mr-1 font-weight-bold px-2`}>{mapping.label}</span>
            </OverlayTrigger>
        </span>
    );
};


export const ShortListDetailColumn = (conversionrate, user,symbol,GetRatio,language,history) => [
    {
        Header: language.WISHLIST_DATE,
        accessor: 'Date',
        className: "text-center align-middle text-nowrap",
        Cell: ( row ) => {
            return (
                <>
                    <div> { row.value ? moment.tz(row.value, user.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD") : "-"} </div>
                    <div className="small text-muted"> { row.value ? moment.tz(row.value, user.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("HH:mm:ss") : "-"} </div>
                </>
            );
        }
        
    },
    {
        Header:"Full Name",
        accessor: (d) => {
            return (
                <>
                    {d.FullName}
                        <ConsumerPopup
                            FullName={d.FullName}
                            Email={d.Email}
                            ContactNumber={d.ContactNumber}
                            comment={d.comment}
                        />
                    {/* {  d.Date ? moment.tz(d.Date, user.timezone ? user.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone).format("YYYY-MM-DD HH:mm:ss") : "-"} */}
                </>
            );
        },
        className:"text-nowrap align-middle text-center",
    },
    {
        Header:language.WISHLIST_STATUS,
        accessor: (d) => {
            return (
                <>
                   {d.stone_status === 1 ? <div className="label label-outline-success label-pill label-inline bg-success-o-30 px-5">Active</div>:<div className="label label-outline-danger label-pill label-inline bg-danger-o-30 px-5">InActive</div>}
                </>
            );
        },
        className:"text-nowrap align-middle text-center",
    },
    {
        Header: language.SEARCHLIST_STOCKID ,
        accessor: (d) => (
            <>
                <span>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip">
                                {d.availability === "Memo" ||
                                d.availability === "Hold"
                                    ? `Business Process`
                                    : d.availability === "Guaranteed"
                                    ? `Guaranteed`
                                    : d.availability === "Not Available"
                                    ? `Not Available`
                                    : d.availability === "Subject to Prior sale"
                                    ? `Subject to Prior sale`
                                    : `Unknown`}
                            </Tooltip>
                        }
                    >
                        <img
                            className="img-fluid availIco mr-2"
                            alt={d.availability}
                            src={toAbsoluteUrl(
                                `/media/svg/${
                                    d.availability === "Memo" ||
                                    d.availability === "Hold"
                                        ? `availability_primary.svg`
                                        : d.availability === "Guaranteed"
                                        ? `availability_success.svg`
                                        : d.availability === "Not Available"
                                        ? `availability_danger.svg`
                                        : d.availability ===
                                        "Subject to Prior sale"
                                        ? `availability_warning.svg`
                                        : `availability_blank_gray.svg`
                                }`
                            )}
                        />
                    </OverlayTrigger>
                </span>
                {(user.CustomerType !=="7" && user.CustomerType !=="11") ? 
                    <a href={`/detail/${d.diamond_type === "L" ? "L" : "N"}-${d.id}`} target="_blank" rel="noreferrer" >
                        {d.diamond_type === "L" ? "L" : "N"}-{d.id}
                    </a> :
                    <span>  {d.diamond_type === "L" ? "L" : "N"}-{d.id}</span>
                }
                <span>
                    {d.country ? (
                        <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        {d.country}
                                    </Tooltip>
                                }
                            >
                                 <img
                                    className="flagIco ml-2"
                                    alt={d.country}
                                    src={toAbsoluteUrl(
                                        `/media/map/${d.country.toLowerCase()}.png`
                                    )}
                                    onError={e => {
                                        e.currentTarget.src =toAbsoluteUrl(
                                            `/media/map/other.png`
                                            )
                                        }}
                                />
                            </OverlayTrigger>
                        </>
                    ) : null}
                </span>
            </>
        ),
        className: "align-middle text-left text-nowrap",
    },
    {
        Header: language.SEARCHLIST_MEDIA ,
        accessor: (d) => (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    {d.aws_image ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Image</Tooltip>}
                            >
                                <ImagePopup
                                    Image={d.aws_image}
                                    Shape={d.C_Shape}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.aws_image}><BsCardImage/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.video ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">Video</Tooltip>}
                            >
                                <VideoPopup
                                    ProductId={d.id}
                                    WLDiamondType={d.diamond_type}
                                    Certi_No={d.Certi_NO}
                                    Video={d.video}
                                    src={d.ext_video}
                                    data={d}
                                />
                                {/* <a className="text-primary mx-2 text-center" href={d.video}><BsFillCameraVideoFill/></a> */}
                            </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                    {d.Certi_NO && user.CustomerType !== "7" && user.CustomerType !=="11" ? (
                        <span>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">Certificate</Tooltip>
                                }
                            >
                                
                            <CertificateLink Certificate_link={d.Certi_link} lab={d.lab} certificate={d.certificate} useIcon className={`mx-2 text-primary text-left`} />
                        </OverlayTrigger>
                        </span>
                    ) : (
                        <span style={{ width: "27px" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </span>
                    )}
                </div>
            </>
        ),
        className: "text-center align-middle",
        disableSortBy: true,
    },
    {
        Header: language.SEARCHLIST_SHAPE,
        accessor: (d) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip">{d.C_Shape}</Tooltip>}
                >
                    <SVG
                        width="25px"
                        height="25px"
                        src={toAbsoluteUrl(
                            `/media/shape/svg_shape/${d.C_Shape.toLowerCase()}.svg`
                        )}
                    />
                </OverlayTrigger>
            </>
        ),
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CARAT ,
        accessor: "C_Weight",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>
                {row.value.toFixed(2)}
            </>;
        },
    },
    {
        Header: language.SEARCHLIST_COLOR,
        accessor: (d) => (
            <>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {d.C_Color && d.C_Color.toLowerCase() === "fancy" ? (
                                <>
                                    {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}
                                </>
                            ) : (
                                d.C_Color
                            )}
                        </Tooltip>
                    }
                >
                    <span
                        className="d-inline-block text-truncate text-dark"
                        style={{ maxWidth: "80px" }}
                    >
                        {d.C_Color && d.C_Color.toLowerCase() === "fancy" ? (
                            <>
                                {d.f_intensity}{" "}{d.f_overtone}{" "}{d.f_color}{" "}
                            </>
                        ) : (
                            d.C_Color
                        )}
                    </span>
                </OverlayTrigger>
            </>
        ),
        minwidth: 50,
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CLARITY,
        accessor: "C_Clarity",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_CUT,
        accessor: "C_Cut",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>{row.value ? row.value : "-"}</>;
        },
    },
    {
        Header: language.SEARCHLIST_POL,
        accessor: "C_Polish",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_SYMM ,
        accessor: "C_Symmetry",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_FLOU,
        accessor: "C_Fluorescence",
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_LAB,
        accessor:"Lab",
        className: "text-center align-middle",
    },
    {
        Header: symbol === "$" ? "$/CT" : `${symbol}/CT`,
        className: "text-right align-middle pr-5",
        accessor: (d) => (
            <>
                {
                    d.stone_status === 1 ?
                        conversionrate !== 1 ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(d.CalculatedPrice.T_C_Rate)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(d.CalculatedPrice.T_C_Rate * conversionrate)}</span>
                            </OverlayTrigger>
                        </> :
                        <span> {symbol}{numberWithCommas(d.CalculatedPrice.T_C_Rate * conversionrate)}</span>
                    :null
                }
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: language.SEARCHLIST_OURPRICE,
        className: "text-right align-middle pr-5",
        // accessor:"OurPrice",
        accessor: (row) => (
            <>
                { row.stone_status === 1 ?
                        conversionrate !== 1 ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(row.CalculatedPrice.total_our_price)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(row.CalculatedPrice.total_our_price * conversionrate)}</span>
                            </OverlayTrigger>
                        </>:
                        <span> {symbol}{numberWithCommas(row.CalculatedPrice.total_our_price * conversionrate)}</span>
                    :null
                }
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: language.SEARCHLIST_DISC,
        className: "text-center align-middle",
        // accessor: (d) => Number(d.CalculatedPrice.discount_main).toFixed(2),
        accessor: (d) => (
            <>
                { d.stone_status === 1 ?
                        <span className={`${(d.CalculatedPrice.discount_main >= 0) ? 'text-success' : 'text-danger'}`}>{ Number(d.CalculatedPrice.discount_main).toFixed(2)}</span>
                    :null
                }
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
        // Cell: (row) => {
        //     return (
        //         <>
        //         {row.value ? <span className={`${(row.value >= 0) ? 'text-success' : 'text-danger'}`}>{row.value}%</span> : <span className={`text-success`}>0.00%</span>}
        //         </>
        //     );
        // },
    },
    {
        Header: "Advanced Tags",
        accessor: (d) => (
            <div className="advTag" style={{ maxWidth: "150px" }}>
                {/* shade */}
                {renderShadeLabel(d.shade)}
               
                { d.Milky && d.Milky.toLowerCase() === "no milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    NO MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-no_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">NO.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.Milky && d.Milky.toLowerCase() === "luster low" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Luster Low
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-luster_low label-pill label-inline label-sm  mr-1 font-weight-bold px-2">LL</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.Milky && d.Milky.toLowerCase() === "light milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    LIGHT MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-light_milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">L.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                { d.Milky && d.Milky.toLowerCase() === "milky" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    MILKY
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-milky label-pill label-inline label-sm  mr-1 font-weight-bold px-2">H.ML</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                 {/* eyeclean */}
                 { d.EyeC && d.EyeC.toLowerCase() === "yes" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    100% Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-info label-pill label-inline label-sm  mr-1 font-weight-bold px-2">100% EC</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.EyeC && d.EyeC.toLowerCase() === "no" ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    No Eye Clean
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  mr-1 font-weight-bold px-2">EC: NO</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }

                {/* growthtype */}
                { d.diamond_type === "L" && (d.brown && d.brown === "CVD") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    CVD
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">CVD</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                { d.diamond_type === "L" && (d.brown && d.brown === "HPHT") ?
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    HPHT
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  mr-1 font-weight-bold px-2">HPHT</span>
                        </OverlayTrigger>
                    </span>
                :
                    null
                }
                
                { (d.diamond_type === "L" ) && (d.lab_treat && d.lab_treat.toLowerCase() === "as grown") ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    As Grown
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-success label-pill label-inline label-sm  font-weight-bold px-2">As-G</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
                {/* natural treated */}
                { (d.diamond_type === "N" || d.diamond_type === "W") && (d.green && d.green.toLowerCase() !== "none")  ? 
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    Treated
                                </Tooltip>
                            }
                        >
                            <span className="label label-light-danger label-pill label-inline label-sm  font-weight-bold px-2">Tx</span>
                        </OverlayTrigger>
                    </span>
                : 
                    null
                }
            </div>
        ),
        className: "align-middle",
    },
    {
        Header: language.SEARCHLIST_RATIO,
        className: "text-center align-middle",
        accessor: (row) => (
            <>
                {row.Ratio?row.Ratio.toFixed(2):"-"}
            </>
        ),
        sortMethod: (a, b) => Number(a) - Number(b),
    },
    {
        Header: language.SEARCHLIST_MEAS,
        accessor: (row) => (
            <>
                {row.C_Length?row.C_Length.toFixed(2):"0.00"}*{row.C_Width?row.C_Width.toFixed(2):"0.00"}*{row.C_Depth?row.C_Depth.toFixed(2):"0.00"}
            </>
        ),
        className: "text-center align-middle",
    },
    {
        Header: language.SEARCHLIST_TABLE,
        accessor: "C_TableP",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>
                {row.value.toFixed(2)}% 
            </>;
        },
    },
    {
        Header: language.SEARCHLIST_DEPTH,
        accessor: "C_DefthP",
        className: "text-center align-middle",
        Cell: (row) => {
            return <>
                {row.value.toFixed(2)}% 
            </>;
        },
    },
    {
        Header: language.SEARCHLIST_CERTIFICATE,
        accessor: (row) => (
            <>
                {row.Certi_NO && user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                    
                    <CertificateLink Certificate_link={row.Certi_link} lab={row.Lab} certificate={row.Certi_No} hideLabName className={`mx-2 text-primary text-left`} />
                ) : ("-")}
            </>
        ),
        className: "text-center align-middle",
    },
    // {
    //     Header:language.CCMODE_SETUP_APPROVED_ACTION,
    //     accessor: (d) => {
    //         const DeleteRow = () => {
    //             const userData = JSON.parse(localStorage.getItem("userData"))
    //             axios({
    //                 method: "POST",
    //                 url: `${NODE_API_URL}/EUR/deleteShortlistedData`,
    //                 headers: { "Content-Type": "application/json" },
    //                 data: {
    //                     Id:[d.Id],
    //                     "CustomerId":userData.CustomerId
    //                 },
    //             }).then(res => {
    //                 if(res && res.data){
    //                     window.location.reload(true)
    //                 }
    //             })
    //         }
    //         return (
    //             <>
    //                 <OverlayTrigger
    //                     placement="top"
    //                     overlay={
    //                         <Tooltip id="tooltip">
    //                             Click for Delete
    //                         </Tooltip>
    //                     }
    //                 >
    //                     {/* <button className="btn btn-danger btn-sm" onClick={() => DeleteSelected()}><FaTrashAlt /></button> */}
    //                     <button className="btn btn-danger btn-sm" onClick={() => DeleteRow()}><FaTrashAlt /></button>
    //                 </OverlayTrigger>
    //             </>
    //         );
    //     },
    //     className:"text-nowrap text-center align-middle",
    // }
];
