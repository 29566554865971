import React, { useState,useRef } from 'react';
import {Snackbar,IconButton,makeStyles,SnackbarContent} from '@material-ui/core';
import {OverlayTrigger,Tooltip,Overlay} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_helpers";
import { FaExternalLinkAlt,FaInfoCircle} from "react-icons/fa";
import { BsLightningFill } from "react-icons/bs";
import {Modal,Button} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import VideoIframe from "../../detailPage/VideoIframe";
import { INSERT_WISHLIST } from '../../../../env_config';
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { MainImagePopup } from '../../widgets/tiles/MainImagePopUp';
// import { HOLDORBUY } from '../../../../env_config';  
// import {ConfirmGoods,HoldPopup} from '../Popup'
import { SwalWarn } from "../../Popup";
import CertificateLink from '../../CertificateLink';

export default function ExpandedDetaisChurned({row,open,conversionrate,symbol,wishlistdata,setUpdateWishlist,language,BuyDiamond,HoldDiamond,symbolname}){
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [overlyShow, setOverlyShow] = useState(false);
    const overlyTarget = useRef(null);

    const [showVideo, setShowVideo] = React.useState(false);
    const [showAssetImg, setShowAssetImg] = React.useState(false);
    const [showArrowImg, setShowArrowImg] = React.useState(false);
    const [showHeartImg, setShowHeartImg] = React.useState(false);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [Toast,SetToast] = useState(false)
    const [wishlistmessage,setWishListMessage] = useState(null)
    const history = useHistory()
    const GenerateTitle = (row) => {
        let title = ""
        // if(row.Lab){
        //     title += row.Lab
        // }
        if(row.C_Shape){
            title += " "
            title += row.C_Shape
        }
        if(row.C_Weight){
            title += " "
            title += row.C_Weight.toFixed(2) 
        }
        // if(row.f_intensity){
        //     title += " "
        //     title += row.f_intensity
        // }
        if(row.C_Color){
            title += " "
            if(row.C_Color.toLowerCase() === "fancy"){
                title += row.f_intensity
                title += " "
                title += row.f_overtone
                title += " "
                title += row.f_color
            } else{
                title += row.C_Color
            }
        }
        if(row.C_Clarity){
            title += " "
            title += row.C_Clarity
        }

        if(row.C_Cut){
            title += " "
            title += row.C_Cut
        }
       
        if(row.C_Polish){
            title += " "
            title += row.C_Polish
        }

        if(row.C_Symmetry){
            title += " "
            title += row.C_Symmetry
        }

        if(row.C_Fluorescence){
            title += " "
            title += row.C_Fluorescence
        }
        return title
    }

    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    
    function handleClose(event, reason) {
        setShowVideo(false);
        setShowHeartImg(false);
        setShowAssetImg(false);
        setShowArrowImg(false);
    }

    function openVideoModal() {
        setShowVideo(true)
    }

    function openHeartModal() {
        setShowHeartImg(true)
    }

    function openAssetModal() {
        setShowAssetImg(true)
    }

    function openArrowModal() {
        setShowArrowImg(true)
    }
 
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));
    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];
    
        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }
    // const handleConfirmPopupShow = () => setConfirmPopupShow(true);
    const [mainimageclick,setMainImageClick] = useState(false)
    // const HoldDiamond = (row) => {
    //     // alert("Diamond hold available from 1st April in INDIA")
    //     setHoldPopupShow(true)
    //     console.log(
    //         confirmPopupShow
    //     );
    //     // const userData = JSON.parse(localStorage.getItem("userData"))
    //     // const finalData = {
    //     //     "user_id":userData.CustomerId,
    //     //     "diamond_info":[
    //     //         {
    //     //             "diamond_id":row.id,
    //     //             "diamond_type":row.diamond_type
    //     //         }
    //     //     ],
    //     //     "operation":"hold"
    //     // }
    //     // axios({
    //     //     method: "POST",
    //     //     url: HOLDORBUY,
    //     //     headers: { "Content-Type": "application/json" },
    //     //     data: finalData,
    //     // }).then(res => {
    //     //     // console.log(res,"res")
    //     //     if(res && res.data){
    //     //         alert(res.data.data[0].status)
    //     //     }
    //     // }).catch(err => {
    //     //     console.log(err)
    //     // })
    // }
    // const handleConfirmPopupShow = () => setConfirmPopupShow(true);
    // const BuyDiamond = (row) => {
    //     setConfirmPopupShow(true)
    //     // alert("Diamond purchase available from 1st April in INDIA")
    //     console.log(
    //         confirmPopupShow
    //     );
    //     // const userData = JSON.parse(localStorage.getItem("userData"))
    //     // const finalData = {
    //     //     "user_id":userData.CustomerId,
    //     //     "diamond_info":[
    //     //         {
    //     //             "diamond_id":row.ProductId,
    //     //             "diamond_type":row.WLDiamondType
    //     //         }
    //     //     ],
    //     //     "operation":"buy"
    //     // }
    //     // axios({
    //     //     method: "POST",
    //     //     url: HOLDORBUY,
    //     //     headers: { "Content-Type": "application/json" },
    //     //     data: finalData,
    //     // }).then(res => {
    //     //     // console.log(res,"res")
    //     //     if(res && res.data){
    //     //         alert(res.data.data[0].status)
    //     //     }
    //     // }).catch(err => {
    //     //     console.log(err)
    //     // })
    // }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function GetRatio(row) {
        let $ratioval
        if (row.C_Shape != 'ROUND') {
            if (row.C_Length >= row.C_Width) {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else if (row.C_Length < row.C_Width) {
                $ratioval = (row.C_Width / row.C_Length).toFixed(2);
            } else if (row.C_Shape == 'HEART') {
                $ratioval = (row.C_Length / row.C_Width).toFixed(2);
            } else {
                $ratioval = '-';
            }
        } else {
            $ratioval = '-';
        }
        return $ratioval
    }
    return(
       <>
        <div className={`expandedDetails ${row.diamond_type === "N" ? "natTab" : "labTab" } ${row.C_Color ==="fancy" ? "fancyExpand" : "" }`} >
            <div className="row pt-2">
                <div className="col-3 d-flex">
                    <ul className="img_thumb_box mt-4 ml-5 pl-0">
                        <li className="img_thumb">
                            {row.aws_image ? (
                                <div>
                                <MainImagePopup Image={row.aws_image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                                <img
                                    className="img thumb"
                                    alt={row.C_Shape}
                                    src={row.aws_image}
                                    onClick={() => setMainImageClick(true)}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/${row.C_Shape ? row.C_Shape.toLowerCase() : ""}-no.png`
                                            )
                                        }}
                                />
                                </div>
                            ) : (
                                <div>
                                    <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                                <img
                                    className="img thumb"
                                    alt="shape"
                                    src={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )}
                                    onError={e => {
                                        e.currentTarget.src = toAbsoluteUrl(
                                            `/media/shape/noimage.jpg`
                                        )
                                    }}
                                />
                                </div>
                            )}
                        </li>
                        {row.video  ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openVideoModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-2.png" height="40px" width="40px"/>
                            </li>
                        ):('')}
                        {row.aws_heart ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openHeartModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-5.png"/>
                            </li>
                            
                        ):('')}
                        {row.aws_arrow ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openArrowModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-6.png"/>
                            </li>
                        ):('')}
                        {row.aws_asset ? (
                            <li className="img_thumb cursor-pointer" onClick={() => openAssetModal()}>
                                <img  alt="img thumb" src="https://www.diamondsoncall.com/assets/front/images/pd-3.png"/>
                            </li>
                        ):('')}
                    </ul>
                    <div className="img_box mt-4">
                        {row.aws_image ? (
                            <div>
                                <MainImagePopup Image={row.aws_image} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>

                            <img
                                className="img-fluid cursor-pointer"
                                alt={row.C_Shape}
                                src={row.aws_image}
                                onClick={() => setMainImageClick(true)}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/${row.C_Shape ? row.C_Shape.toLowerCase() : ""}-no.png`
                                        )
                                    }}

                            />
                            </div>
                        ) : (
                            <div>
                                <MainImagePopup Image={toAbsoluteUrl( `/media/shape/${row.C_Shape.toLowerCase()}-no.png` )} Shape={row.C_Shape} mainimageclick={mainimageclick} setMainImageClick={setMainImageClick}/>
                            <img
                                className="img-fluid"
                                alt="shape"
                                src={toAbsoluteUrl(
                                    `/media/shape/${row.C_Shape.toLowerCase()}-no.png`
                                )}
                                onError={e => {
                                    e.currentTarget.src = toAbsoluteUrl(
                                        `/media/shape/noimage.jpg`
                                    )
                                }}
                            />
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-3">
                    <div className="row">
                        <div className="col-12 my-3 d-flex align-items-center">
                            
                            <div className="mr-2"> 
                                {row.country ? (
                                    <OverlayTrigger
                                        placement="top"
                                        className="text-capitalize"
                                        overlay={
                                            <Tooltip id="tooltip">{row.country}</Tooltip>
                                        }
                                    >
                                        <img
                                            className="flagIco mr-1"
                                            alt={row.country}
                                            src={toAbsoluteUrl(
                                                `/media/map/${row.country.toLowerCase()}.png`
                                            )}
                                            onError={e => {
                                                e.currentTarget.src =toAbsoluteUrl(
                                                    `/media/map/other.png`
                                                    )
                                                }}
                                        />
                                    </OverlayTrigger>
                                ) : (
                                    null
                                )}
                            </div >
                            
                            <div className="mr-2 border-right text-primary">
                                {row.Lab && user.CustomerType !=="7" && user.CustomerType !=="11" ? <>{row.Lab}:</> : ""}{row.Certi_NO &&  user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                                    
                                    <CertificateLink Certificate_link={row.Certi_link} lab={row.Lab} certificate={row.Certi_NO} hideLabName withExternalLinkIcon className={`mx-2 text-primary text-left`} />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div >{language.MUI_STOCKNO}: <div className="text-primary d-inline-block mr-2">{row.diamond_type + "-" + row.id}</div>
                                <span>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `Business Process`
                                                    : row.availability === "Guaranteed"
                                                    ? `Guaranteed`
                                                    : row.availability === "Not Available"
                                                    ? `Not Available`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `Subject to Prior sale`
                                                    : `Unknown`
                                                }
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            className="img-fluid availIco mr-2"
                                            alt={row.availability}
                                            src={toAbsoluteUrl(`/media/svg/${
                                                row.availability === 'Memo' || row.availability === 'Hold'
                                                    ? `availability_primary.svg`
                                                    : row.availability === "Guaranteed"
                                                    ? `availability_success.svg`
                                                    : row.availability === "Not Available"
                                                    ? `availability_danger.svg`
                                                    : row.availability === "Subject to Prior sale"
                                                    ? `availability_warning.svg`
                                                    : `availability_blank_white.svg`
                                            }`)}
                                        />
                                    
                                    </OverlayTrigger>
                                </span>
                            </div>
                        </div>
                        <div className="col-10 align-self-center">
                            <span className="text-dark font-size-h3 font-weight-bold text-capitalize">{GenerateTitle(row)}</span>
                        </div>
                        {/* {!(wishlistdata.find(value => value.id.toString() === row.id.toString()))?<div className="col-2">
                            <OverlayTrigger
                                placement="top"
                                className="text-capitalize"
                                overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                            >
                                <span className="btn btn-primary btn-pill btn-icon" onClick={() => AddToWishlist(row)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 20.844 24.063" className="btn_whs_svg">
                                        <path id="Subtraction_1" data-name="Subtraction 1" d="M27.087, 552.075V528H47.952v24.072L37.52, 543.046l-10.433, 9.028h0Zm8.23-21.066a2.6, 2.6, 0, 0, 0-1.841, 4.452l4.045, 4.045, 4.044-4.045a2.6, 2.6, 0, 1, 0-3.683-3.682l-0.362.362-0.363-.362A2.589, 2.589, 0, 0, 0, 35.317, 531.009Z" fill="#fff" stroke="#fff" transform="translate(-27.094 -528)"></path>
                                    </svg>
                                </span>
                            </OverlayTrigger>
                            
                        </div>:<div className="col-2">
                            <OverlayTrigger
                                placement="top"
                                className="text-capitalize"
                                overlay={<Tooltip id="tooltip">Stone already in tracklist</Tooltip>}
                            >
                                <span className="btn btn-secondary btn-pill btn-icon" onClick={() => AddToWishlist(row)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="13" viewBox="0 0 20.844 24.063" className="btn_whs_svg">
                                        <path id="Subtraction_1" data-name="Subtraction 1" d="M27.087, 552.075V528H47.952v24.072L37.52, 543.046l-10.433, 9.028h0Zm8.23-21.066a2.6, 2.6, 0, 0, 0-1.841, 4.452l4.045, 4.045, 4.044-4.045a2.6, 2.6, 0, 1, 0-3.683-3.682l-0.362.362-0.363-.362A2.589, 2.589, 0, 0, 0, 35.317, 531.009Z" fill="#fff" stroke="#fff" transform="translate(-27.094 -528)"></path>
                                    </svg>
                                </span>
                            </OverlayTrigger>
                            
                        </div>} */}
                        {row.show_supplier === 0 ? (
                            ""
                        ):(
                            <div className="col-12 mt-3">
                                { user.CustomerType ==="7" || user.CustomerType ==="11" ? (
                                    <span className="font-size-lg"><span className="text-dark font-size-sm font-weight-bold text-capitalize">{language.MUI_SUPPLIER} :</span> ***************</span>
                                ) :(
                                    <span className="font-size-lg"><span className="text-dark font-size-sm font-weight-bold text-capitalize">{language.MUI_SUPPLIER} :</span> {row.C_Name}</span>
                                )}
                            </div>
                        )}
                        
                        <div className="col-12 mt-3 d-flex">
                            <span className="font-size-lg text-dark-50"><span className="font-size-sm font-weight-bold text-capitalize">{language.MUI_LISTPRICE}: </span>${row.raprate?row.raprate:"0000"}</span>
                            <span className="font-size-lg text-dark-50 ml-8"><span className="font-size-sm font-weight-bold text-capitalize">{language.SEARCHGRID_SHIPPINGDAYS}: </span>{<> {row.shipping_days}{" "}{language.MUI_DAYS} </>}</span>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="row"> 
                            {row.show_supplier === 0 ?(
                                ""
                            ) : (
                                <div className="col-6">
                                    <div className="price_border_right"></div>
                                    <div className="px-0">
                                        <h5>
                                            {language.MUI_SUPPLIERPRICE}
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Price Provided by Supplier
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="ml-2 text-hover-primary cursor-pointer"><FaInfoCircle /></span>
                                                </OverlayTrigger>
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.SupplierDiscount?row.SupplierDiscount.includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.SupplierDiscount}% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.SupplierDiscount}% PR<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>} 
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.SupplierRate)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.SupplierRate * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.SupplierRate * conversionrate)}</>
                                            </span>
                                        }
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.SupplierPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.SupplierPrice * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.SupplierPrice * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            )}
                                <div className="col-6">
                                    <div className="px-0">
                                        <h5 className="position-relative">
                                            {language.MUI_OURPRICE}
                                            {row.show_supplier === 0 ? (
                                                ""
                                            ):(
                                                <>
                                                    <span className="ml-2 text-hover-primary cursor-pointer" ref={overlyTarget} onClick={() => setOverlyShow(!overlyShow)}><FaInfoCircle /></span>
                                                    <Overlay target={overlyTarget.current} show={overlyShow} placement="bottom">
                                                        {props => (
                                                            <Tooltip id="overlay-example" {...props}>
                                                            Supplier +  <a href='/transparentpricing' target='_blank'>our charge</a>
                                                            </Tooltip>
                                                        )}
                                                    </Overlay>
                                                </>
                                            )}
                                        </h5>
                                        <hr className="w-25 ml-0 my-2"/>
                                        {row.discount_main?row.discount_main.includes('-')?
                                        <div className="d-flex small text-danger align-items-center">{row.discount_main}% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>:
                                        <div className="d-flex small text-success align-items-center">{row.discount_main}% PR<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>
                                        :<div className="d-flex small text-danger align-items-center">0.00% Off<span className="ml-8 text-dark font-size-lg">({symbolname})</span></div>}
                                        
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.OurPrice)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="h1">
                                                        <><span className="small">{symbol}</span >{numberWithCommas(row.OurPrice * conversionrate)}</>
                                                    </span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="h1">
                                                <><span className="small">{symbol}</span >{numberWithCommas(row.OurPrice * conversionrate)}</>
                                            </span>
                                        }  
                                        {
                                            conversionrate !== 1 ? <>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            ${numberWithCommas(row.OurRate)}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.OurRate * conversionrate)}</span></span>
                                                </OverlayTrigger>
                                            </> :
                                            <span className="d-flex small text-dark-50 align-items-center">Per Ct. <span className="font-size-sm text-dark-50 ml-2">{symbol}{numberWithCommas(row.OurRate * conversionrate)}</span></span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex align-items-center">
                            <button type="button" className="btn btn-primary px-10 mr-3 btn-pill font-weight-bold" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond([{original:row}]) :  DetailSemiAprrove() }><BsLightningFill /> Confirm Diamond</button>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="row">
                        <div className="col-6 border-left pb-10">
                            { (row.C_Color && row.C_Color.toLowerCase() ==="fancy") ? (
                                <>
                                    <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">Fancy Color Details </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Intensity</div>
                                        <div className="col-6 font-weight-bold">  {row.f_intensity ? row.f_intensity : "-"} </div>
                                    </div>
                                    <div className="row mb-1">
                                        <div className="col-6">Overtone</div>
                                        <div className="col-6 font-weight-bold">  {row.f_overtone ? row.f_overtone : "-"} </div>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-6">Color</div>
                                        <div className="col-6 font-weight-bold">  {row.f_color ? row.f_color : "-"} </div>
                                    </div>
                                </>
                            ):('')}
                            <div className="font-size-h5 text-dark font-weight-bolder mb-3 mt-5">{language.MUI_PARAM_DETAILS} </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_MEASUREMENT} </div>
                                <div className="col-6 font-weight-bold"> {row.C_Length} * {row.C_Width} * {row.C_Depth} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6 "> {language.MUI_TABLE} (%) </div>
                                <div className="col-6 font-weight-bold"> {row.C_TableP ? row.C_TableP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6"> {language.MUI_DEPTH} (%)</div>
                                <div className="col-6 font-weight-bold"> {row.C_DefthP ? row.C_DefthP : "-"} </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Crn_Ht ? row.Crn_Ht : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_CRANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Crn_Ag ? row.Crn_Ag : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                {language.MUI_PAVHEIGHT}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Pav_Dp ? row.Pav_Dp : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_PAVANGLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                    {row.Pav_Ag ? row.Pav_Ag : "-"}
                                </div>
                            </div>
                            
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_RATIO}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {GetRatio(row)}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLE}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.gridle_per ? row.gridle_per : "-"}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <div className="col-6">
                                    {language.MUI_GIRDLECONDTION}
                                </div>
                                <div className="col-6 font-weight-bold">
                                {row.gridle  ? row.gridle : "-"}
                                </div>
                            </div>
                            
                        </div>
                        {(user.CustomerType !=="7" && user.CustomerType !=="11") ? (
                            <div className="col-6 border-left">
                                <h5 className="font-size-h5 text-dark font-weight-bolder mb-3  mt-5">{language.MUI_ADDITIONALDETAILS}</h5>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_BRAND}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.canada_mark ? row.canada_mark : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_SHADE}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.shade  ? row.shade : "-"}
                                    </div>
                                </div>
                                <hr className="w-75 ml-0"/>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_MILKY}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.Milky  ? row.Milky : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">
                                        {language.MUI_EYECLEAN}
                                    </div>
                                    <div className="col-6 font-weight-bold">
                                        {row.EyeC ? row.EyeC : "-"}
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6"> {language.MUI_CULET} </div>
                                    <div className="col-6 font-weight-bold"> {row.cutlet  ? row.cutlet : "-"} </div>
                                </div>
                                <div className="row mb-1">
                                    <div className="col-6">{language.MUI_KEY}</div>
                                    <div className="col-6 font-weight-bold">{row.Key_Symbols ? row.Key_Symbols : "-" }</div>
                                </div>
                                    {row.diamond_type === "L" ? 
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_GROWTHTYPE} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            { (row.lab_treat) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.lab_treat === "Treated"?"Post Growth Treatment":row.lab_treat} </div>
                                                </div>
                                            ):(row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    :   
                                        <>
                                            { (row.brown) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_ORIGIN} </div>
                                                    <div className="col-6 font-weight-bold">  {row.brown ? row.brown : "-"} </div>
                                                </div>
                                            ):('')}
                                            {/* { (row.canada_mark) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_BRAND} </div>
                                                    <div className="col-6 font-weight-bold">  {row.canada_mark ? row.canada_mark : "-"} </div>
                                                </div>
                                            ):('')} */}
                                            { (row.green) ? (
                                                <div className="row mb-1">
                                                    <div className="col-6"> {language.MUI_TREATMENT} </div>
                                                    <div className="col-6 font-weight-bold">  {row.green ? row.green : "-"} </div>
                                                </div>
                                            ):('')}
                                        </>
                                    }
                            </div>
                            ) : ("")}
                    </div>
                </div>
            </div>
        </div>
                    
          {/* {confirmPopupShow === true && <ConfirmGoods show={confirmPopupShow} onHide={handleConfirmPopupClose} {...row} />}  
          {holdPopupShow === true && <HoldPopup show={holdPopupShow} onHide={handleHoldPopupClose} {...row} />}   */}
           <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 

            <Modal
                show={showHeartImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_heart} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showArrowImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_arrow} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showAssetImg}
                onHide={handleClose}
                size="lg"
            >
                <Modal.Body className="text-center">
                    <img src={row.aws_asset} alt={row.C_Shape} className="img-fluid" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <VideoIframe
                showVideo={showVideo}
                handleClose={handleClose}
                src={row.diamond_type === "N" ? `https://pro360video.com/video.php?refno=${row.Certi_NO}` : `https://pro360video.com/labgrown.php?refno=${row.Certi_NO}` }
                // src={row.ext_video || ""}
                data={row}
           />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={Toast}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={wishlistmessage}
                />
            </Snackbar>
      </>
    )
}