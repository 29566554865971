/* eslint-disable */
export default {
    formId: 'kycForm',
    formField: {
      firstName: {
        name: 'firstName',
        label: 'First name*',
        requiredErrorMsg: 'First name is required'
      },
      lastName: {
        name: 'lastName',
        label: 'Last name*',
        requiredErrorMsg: 'Last name is required'
      },
     
      email: {
        name: 'email',
        label: 'Email*',
        requiredErrorMsg: 'Email id is required'
      },
      contanct_no: {
        name: 'contanct_no',
        label: 'Contact Number*',
        requiredErrorMsg: 'Contact Number id is required'
      },
      passport_id: {
        name: 'passport_id',
        label: 'Identity Number *:',
        requiredErrorMsg: 'Photo ID Number id is required'
      },
      passport_id_file: {
        name: 'passport_id_file',
        label: '',
        requiredErrorMsg: 'File is required'
      },
     
      work_profile: {
        name: 'work_profile',
        label: 'Work Profile*',
        requiredErrorMsg: 'Work Profile is required'
      },
      useAddressForPaymentDetails: {
        name: 'useAddressForPaymentDetails',
        label: 'Use this address for payment details'
      },
      company_name: {
        name: 'company_name',
        label: 'Company Name*',
        requiredErrorMsg: 'Company Name required'
      },
      logo: {
        name: 'logo',
        label: 'Logo*',
        requiredErrorMsg: 'Logo is required'
      },
      billing_company_name: {
        name: 'billing_company_name',
        label: 'Billing Company Name',
        requiredErrorMsg: 'Billing Company Name is required'
      },
      address1: {
        name: 'address1',
        label: 'Address Line 1*',
        requiredErrorMsg: 'Address Line 1 is required'
      },
      address2: {
        name: 'address2',
        label: 'Address Line 2',
        requiredErrorMsg: 'Address Line 2 is required'
      },
      address3: {
        name: 'address3',
        label: 'Address Line 3',
        requiredErrorMsg: 'Address Line 3 is required'
      },
      city: {
        name: 'city',
        label: 'City*',
        requiredErrorMsg: 'City is required'
      },
      state: {
        name: 'state',
        label: 'State*',
        requiredErrorMsg: 'State is required'
      },
      zipcode: {
        name: 'zipcode',
        label: 'Zip code*',
        requiredErrorMsg: 'Zip code is required',
        invalidErrorMsg: 'Zip code is not valid (e.g. 395001)'
      },
      country: {
        name: 'country',
        label: 'Country*',
        requiredErrorMsg: 'Country is required',
      },
      telephone: {
        name: 'telephone',
        label: 'Telephone*',
        requiredErrorMsg: 'telephone is required'
      },
      fax: {
        name: 'fax',
        label: 'Fax',
        requiredErrorMsg: 'Fax is required'
      },
      invoice_email: {
        name: 'invoice_email',
        label: 'Invoice Notification Email*',
        requiredErrorMsg: 'Invoice Notification Email is required'
      },
      website: {
        name: 'website',
        label: 'Website',
        requiredErrorMsg: 'Website is required'
      }, 
      comp_reg_no: {
        name: 'comp_reg_no',
        label: 'Company Reg. Number *',
        requiredErrorMsg: 'Company Reg. Number  is required'
      },
      comp_reg_no_file: {
        name: 'comp_reg_no_file',
        label: 'Company Reg. File*',
        requiredErrorMsg: 'Company Reg. File is required'
      },
      nature_of_business: {
        name: 'nature_of_business',
        label: 'Nature of Business*',
        requiredErrorMsg: 'Nature of Business is required'
      },
      business_est_date: {
        name: 'business_est_date',
        label: 'Business Establishment Date*',
        requiredErrorMsg: 'Business Establishment Date is required'
      },
      name_of_partner: {
        name: 'name_of_partner',
        label: 'Name of Director/ Partner/ Proprietor*',
        requiredErrorMsg: 'Name of Director/ Partner/ Proprietor is required'
      },
      contact_of_partner: {
        name: 'contact_of_partner',
        label: 'Contact Number of Director/ Partner/ Proprietor*',
        requiredErrorMsg: 'Contact Number of Director/ Partner/ Proprietor is required'
      },
      shipping_address1: {
        name: 'shipping_address1',
        label: 'Address Line 1*',
        requiredErrorMsg: 'Address Line 1 is required'
      },
      shipping_address2: {
        name: 'shipping_address2',
        label: 'Address Line 2',
        requiredErrorMsg: 'Address Line 2 is required'
      },
      atten_name: {
        name: 'atten_name',
        label: 'Atten Name',
        requiredErrorMsg: 'Atten Name is required'
      },
      shipping_address3: {
        name: 'shipping_address3',
        label: 'Address Line 3',
        requiredErrorMsg: 'Address Line 3 is required'
      },
      shipping_city: {
        name: 'shipping_city',
        label: 'City*',
        requiredErrorMsg: 'City is required'
      },
      shipping_state: {
        name: 'shipping_state',
        label: 'State*',
        requiredErrorMsg: 'State is required'
      },
      shipping_zipcode: {
        name: 'shipping_zipcode',
        label: 'Zip code*',
        requiredErrorMsg: 'Zip code is required',
        invalidErrorMsg: 'Zip code is not valid (e.g. 395001)'
      },
      shipping_country: {
        name: 'shipping_country',
        label: 'Country*',
        requiredErrorMsg: 'Country is required'
      },
      shipping_telephone: {
        name: 'shipping_telephone',
        label: 'Telephone*',
        requiredErrorMsg: 'Telephone is required'
      },
      shipping_email: {
        name: 'shipping_email',
        label: 'Shipping email*',
        requiredErrorMsg: 'Shipping email is required'
      },
      tax_id: {
        name: 'tax_id',
        label: 'Company Tax Id.*',
        requiredErrorMsg: 'Company Tax Id. is required'
      },
      port_of_discharge: {
        name: 'port_of_discharge',
        label: 'Port of discharge*',
        requiredErrorMsg: 'Port of discharge is required'
      },
      same_as_shipping_address: {
        name: 'same_as_shipping_address',
        label: 'Shipping Address Same as Company Information',
      },
    }
  };
  