import React, { useState } from 'react';
import {
  Backdrop,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import axios from "axios"
import PersonalInfoForm from '../KycUpdate/FormsEdit/PersonalInfoForm';
import CompanyInfoForm from '../KycUpdate/FormsEdit/CompanyInfoForm';
// import ReviewOrder from './ReviewOrder';
import { NODE_API_URL,BASE_URL } from '../../../../env_config';
import validationSchemaEdit from '../KycUpdate/FormModel/validationSchemaEdit';
import kycFormModel from '../KycUpdate/FormModel/kycFormModel';
import formInitialValues from '../KycUpdate/FormModel/formInitialValues';
import { toAbsoluteUrl } from "../../../_helpers";
import { FaMapMarkerAlt } from "react-icons/fa";
import useStyles from '../KycUpdate/styles';
// import Scrollspy from 'react-scrollspy'
import { SwalWarn,SwalSuccess} from "../../Popup";
import Cookies from "js-cookie"
import { Modal, Nav,Tab } from 'react-bootstrap';
import ShippingAddress from './ShippingAddress';
import { CiUser } from 'react-icons/ci';
import { LiaShippingFastSolid } from 'react-icons/lia';
const steps = ['User Information', 'Company Profile', 'Shipping Information'];
const { formId, formField } = kycFormModel;

export default function ProfileEdit(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchemaEdit[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [FirstFile,setFirstFile] = useState()
  const [SecondFile,setSecondFile] = useState()
  const [ThirdFile,setThirdFile] = useState()
  const [passportfilename,setPassportFileName] = useState("")
  const [companyregname,setCompanyRegName] = useState("")
  const [swalWarnOpen,setSwalWarnOpen] = useState(false)
  const [swalSuccessOpen,setSwalSuccessOpen] = useState(false)
  const [alertMessage,setAlertMessage] = useState()
  const [formaction,setFormAction] = useState()
  const [formvalue,setFormValue] = useState()
  const [loading, setLoading] = React.useState(false);
  const[isSubmitting ,SetIsSubmitting] = useState(false)
  const [openLoader, setOpenLoader] = React.useState(false);

  const enableLoading = () => {
      setLoading(true);
      setOpenLoader(true);
      SetIsSubmitting(true)
  };
  const disableLoading = () => {
      setLoading(false);
      setOpenLoader(false);
      SetIsSubmitting(false)
  };

  const cancelSwal = () => {
    // setSemiAprroveModal(true)
    setSwalWarnOpen(true)
    setAlertMessage("Are you sure want to Cancel !!")
}

const refreshoncacel = () => {
  window.location.reload(true)
}

  function _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function _submitForm(values, actions) {
    // console.log("Submitttttt")
    await _sleep(1000);
    // alert(JSON.stringify(values, null, 2));
    // console.log("profile",JSON.stringify(values, null, 2))
    actions.setSubmitting(false);
    const userData = JSON.parse(localStorage.getItem("userData"))
    //   console.log(values,"values")
    const formData = new FormData();
    if(FirstFile){
      formData.append("user_file", FirstFile[0])

    }
    if(SecondFile){
    formData.append("user_file", SecondFile[0])
      
    }
    if(ThirdFile){
      formData.append("user_file", ThirdFile[0])

    }
    let shippingaddress = ""
    if(values.shipping_address1){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address1
      shippingaddress += "</p>"
    }
    if(values.shipping_address2){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_address2
      shippingaddress += "</p>"
    }
    else{
      shippingaddress += "<p>"
      shippingaddress += '"'
      shippingaddress += "</p>"
    }
    if(values.shipping_city){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_city
      shippingaddress += "</p>"
    }
    if(values.shipping_state){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_state
      shippingaddress += "</p>"
    }
    if(values.shipping_zipcode){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_zipcode
      shippingaddress += "</p>"
    }
    if(values.shipping_country){
      shippingaddress += "<p>"
      shippingaddress += values.shipping_country
      shippingaddress += "</p>"
    }
    // console.log(values.shipping_address1.split('\n'),"Splitting")
    let newshippingadress = "<p>"+values.shipping_address1.replaceAll("\n","<br>").replaceAll("\r","<br>")+"</p>"
    // console.log(shippingaddress,"shippingaddress")
    const DataObj = {
      // user_file:[FirstFile[0],SecondFile[0],ThirdFile[0]],
      "cname":values.company_name,
      "fname":values.firstName,
      "lname":values.lastName,
      "mail":values.email,
      invoiceemail:values.email,
      mobile:values.contanct_no,
      passport_id:values.passport_id,
      passport_file:values.passport_id_file,
      work_profile:values.work_profile,
      address:values.address1,
      secaddress:values.address2,
      city:values.city,
      state:values.state,
      country:values.country,
      com_zip:values.zipcode,
      com_reg_no:values.comp_reg_no,
      com_reg_doc:values.comp_reg_no_file,
      nature_buss:values.nature_of_business,
      buss_esta_date:values.business_est_date,
      director_name:values.name_of_partner,
      directory_contact:values.contact_of_partner,
      id:userData.CustomerId,
      company_tax:values.tax_id,
      website:values.website,
      invoiceemail:values.invoice_email,
      fax:values.fax,
      contact2:values.telephone,
      company_name_official:values.billing_company_name,
      addressline3: values.address3
    }
    for ( var key in DataObj ) {
      formData.append(key, DataObj[key]);
    }
    enableLoading();
    axios({
      method: "POST",
      url: `${NODE_API_URL}/EUR/kycInsertorUpdateProfile`,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
      },
      data: formData,
    }).then(res => {
        disableLoading()
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
        window.location.reload(true)
        // console.log(res)
    }).
    catch(error => {
      if(error?.response?.data?.message === "UnAuthorized!"){
        var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        if(cookies[i] && !cookies[i].includes("view")){
          var equals = cookies[i].indexOf("=");
          var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }
      
      window.location.replace(`${BASE_URL}/logout`);
    }
    })
  }

  function _handleSubmit(values, actions) {
    _submitForm(values, actions);
   
  }
  function submitSwal(values, actions) {
    setFormValue(values)
    setFormAction(actions)
    setSwalSuccessOpen(true)
    setAlertMessage("Are you sure want to save !!")
  }


  return (
    <>
      <Modal 
        show={props.show}
        onHide={props.onClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Profile Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
              initialValues={formInitialValues}
              validationSchema={currentValidationSchema}
              onSubmit={submitSwal}
            >
              {({ setFieldValue, values }) => (
                <Form id={formId}>
                    {/* <div className='custom-scrollbar' style={{maxHeight:"450px",overflowX:"hidden"}}> */}
                      <PersonalInfoForm
                        setFieldValue={setFieldValue}
                        formField={formField}
                        setFirstFile={setFirstFile}
                        values={values}
                        userinfo={props.userinfo}
                        language={props.language}
                        setPassportFileName={setPassportFileName}
                      />
    
                      <CompanyInfoForm
                        formField={formField}
                        setFirstFile={setFirstFile}
                        setSecondFile={setSecondFile}
                        setThirdFile={setThirdFile}
                        values={values}
                        setFieldValue={setFieldValue}
                        setPassportFileName={setPassportFileName}
                        setCompanyRegName={setCompanyRegName}
                        FirstFile={FirstFile}
                        SecondFile={SecondFile}
                        ThirdFile={ThirdFile}
                        language={props.language}
                      />
    
                    {/* </div> */}
                  <Grid
                    className="border-top sticky-top bg-white"
                    container
                    justify={activeStep !== 0 ? 'space-between' : 'flex-end'}
                    alignItems="center"
                    style={{ bottom: '0px' }}
                  >
                    <div className={classes.wrapper}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {props.language.PROFILE_EDIT_SAVE_CHANGES}
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        // onClick={() => cancelSwal()}
                        onClick={() => props.onClose()}
                        className={classes.button}
                      >
                        {props.language.PROFILE_EDIT_CANCEL}
                      </Button>
                      {loading && <span className="ml-1 spinner spinner-white"></span>}
                      
                    </div>
                  </Grid>
                </Form>
              )}
            </Formik>
        </Modal.Body>
      </Modal>
      {/* <Tab.Container id="profileTab" defaultActiveKey="personal">
        <div  className="row">
          <div className="col-md-4 text-center">
             <div className='sticky-top'style={{top:"132px"}}>
               <div className="col-12">
                  {props.userinfo.company_logo?<img
                      alt="profile"
                      className="img-fluid profileLogo rounded-circle bg-light p-2"
                      width="125px"
                      src={process.env.REACT_APP_ENV === "production"?`https://www.diamondsoncall.com/assets/documents/customer_logo/${props.userinfo.company_logo}`:`https://alpha.diamondsupload.com/assets/customer_logo/${props.userinfo.company_logo}`}
  
                  />:<img
                  alt="profile"
                  className="img-fluid profileLogo rounded-circle bg-light p-2"
                  width="125px"
                  src={toAbsoluteUrl(`/media/General/usericon.png`)}
              />}
               </div >
                <div className="font-size-h4 mt-5 text-capitalize font-weight-bolder col-12">
                    {props.userinfo.cname}
                </div>
                <div className="font-size-h5 text-muted mt-1 text-capitalize font-weight-bold col-12">
                    <FaMapMarkerAlt className="mr-2" />
                    {props.userinfo.city}, {props.userinfo.country}
                </div>
                <div className="col-12">
                  <Nav variant="pills" className="flex-column mt-md-10 mt-3 profile_nav custom_nav">
                    <Nav.Item>
                      <Nav.Link eventKey="personal" >
                        <CiUser className="mr-2"/>
                        {props.language.PROFILEVIEW_PROFILE_INFO}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="shipping">
                        <LiaShippingFastSolid className="mr-2"/>
                        Manage {props.language.PROFILEVIEW_SHIPPING_INFORMATION}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div >
             </div >
            
          </div>
          
          <div className="col-md-8 bg-white p-8">
          <Tab.Content>
            <Tab.Pane eventKey="personal">
              <Formik
                initialValues={formInitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={submitSwal}
              >
                {({ setFieldValue, values }) => (
                  <Form id={formId}>
                      <PersonalInfoForm
                        setFieldValue={setFieldValue}
                        formField={formField}
                        setFirstFile={setFirstFile}
                        values={values}
                        userinfo={props.userinfo}
                        language={props.language}
                        setPassportFileName={setPassportFileName}
                      />
  
                      <CompanyInfoForm
                        formField={formField}
                        setFirstFile={setFirstFile}
                        setSecondFile={setSecondFile}
                        setThirdFile={setThirdFile}
                        values={values}
                        setFieldValue={setFieldValue}
                        setPassportFileName={setPassportFileName}
                        setCompanyRegName={setCompanyRegName}
                        FirstFile={FirstFile}
                        SecondFile={SecondFile}
                        ThirdFile={ThirdFile}
                        language={props.language}
                      />
  
                    <Grid
                      className="mt-10 sticky-top bg-white"
                      container
                      justify={activeStep !== 0 ? 'space-between' : 'flex-end'}
                      alignItems="center"
                      style={{ bottom: '0px' }}
                    >
                      <div className={classes.wrapper}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          {props.language.PROFILE_EDIT_SAVE_CHANGES}
                        </Button>
                        <Button
                          type="button"
                          variant="contained"
                          onClick={() => cancelSwal()}
                          className={classes.button}
                        >
                          {props.language.PROFILE_EDIT_CANCEL}
                        </Button>
                        {loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop
                          sx={{ color: '#002173', zIndex: theme => theme.zIndex.drawer + 5 }}
                          open={openLoader}
                        >
                          <CircularProgress color="secondary" />
                        </Backdrop>
                      </div>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Tab.Pane>
  
           
          </Tab.Content>
            
          </div>
        </div>
    </Tab.Container> */}
      <Backdrop className={classes.backdrop} open={openLoader} >
        <CircularProgress color="secondary" />
      </Backdrop>
    <SwalSuccess show={swalSuccessOpen} message={alertMessage} onHide={() => setSwalSuccessOpen(false)} onClose={() => setSwalSuccessOpen(false)} responseOnOk={() => _handleSubmit(formvalue,formaction)}/> 
    <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => setSwalWarnOpen(false)} responseOnOk={() => refreshoncacel()}/> 
    </>
  );
}
