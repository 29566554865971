import React, { useState ,useEffect} from "react";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_helpers";
import axios from "axios";
import { makeStyles , Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { Backdrop,CircularProgress,} from "@material-ui/core";
import { CiBank, CiCreditCard2 } from "react-icons/ci";
import Cookies from "js-cookie"
const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(0),
    },
    group: {
      margin: theme.spacing(1, 0),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor:"#ffffffe6!important"
      },
      noScroll: {
          overflow: 'hidden'
      }
  }));
  

export const RequestForInvoice = (props) => {
    const [convinienceobj,setConvinienceObj] = useState()
    const [vipdiscount] = useState(0)
    useEffect(() => {
        axios({
            method: "GET",
            url: `${NODE_API_URL}/EUR/get-conviniencefee`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params: {
                country:props.userinfo.country
            },
        }).then(async function(res) {
            if(res && res.data && res.data.data){
                setConvinienceObj(res.data.data)
                if (res.data.data.fedex_charges != 0 && res.data.data.ups_charges != 0 && res.data.data.hk_delivery != "Y") {                	
                    setShippingCharge(res.data.data.ups_charges);
                    setValue("ups")
				}
				if (res.data.data.fedex_charges != 0 && res.data.data.ups_charges == 0 && res.data.data.hk_delivery != "Y") {                	
                    setShippingCharge(res.data.data.fedex_charges);
                    setValue("fedex")
				}
				if (res.data.data.ups_charges != 0 && res.data.data.fedex_charges == 0 && res.data.data.hk_delivery != "Y") {
                	setShippingCharge(res.data.data.ups_charges);
                    setValue("ups")
				}
				if (res.data.data.fedex_charges != 0 && res.data.data.ups_charges != 0 && res.data.data.hk_delivery == "Y") {
                	 setShippingCharge(res.data.data.ups_charges);
                     setValue("ups")
				}
				if (res.data.data.fedex_charges == 0 && res.data.data.ups_charges == 0 && res.data.data.hk_delivery == "Y") {
                	setShippingCharge(0);
                    setValue("hkdelivery")
				}
				if (res.data.data.fedex_charges != 0 && res.data.data.ups_charges == 0 && res.data.data.hk_delivery == "Y") {
                	setShippingCharge(res.data.data.fedex_charges);
                    setValue("fedex")
				}
				if (res.data.data.fedex_charges == 0 && res.data.data.ups_charges != 0 && res.data.data.hk_delivery == "Y") {
                	 setShippingCharge(res.data.data.ups_charges);
                     setValue("ups")
				}
            }
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
        let cgi = props.diamonddata.map(value => {
            return value.conform_good_id
        })
        // axios({
        //     method: "POST",
        //     url: `${NODE_API_URL}/EUR/getVipDiscount`,
        //     headers: { "Content-Type": "application/json" },
        //     data:{
        //         "user_id":props.userinfo.id,
        //         "conform_good_id":cgi
        //     }
        // })
        // .then(async function(res) {
        //         if(res && res.data && res.data.success){
        //             setVipDiscount(res.data.vip_discount)
        //         }
        // })
        // .catch(function(res) {
            
        // });
    },[])
    // console.log(selectshippingcharge,"selectshippingcharge")
    const classes = useStyles();
    const [value, setValue] = React.useState('ups');
    const history = useHistory()
    

    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    function numberWithCommas(x) {
        if(x){
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            return "0.00"
        }
    }
    const [shippingcharge,setShippingCharge] = useState(0)
    // const HandleClick = (e) => {
    //     console.log(e.target.value,"BALUEEEEE")
    //     setShippingCharge(parseFloat(e.target.value))
    // }
    function handleChange(event) {
        setValue(event.target.value);
        if(event.target.value === "ups"){
        setShippingCharge(parseFloat(convinienceobj.ups_charges))

        }
        else if(event.target.value === "fedex"){
            setShippingCharge(parseFloat(convinienceobj.fedex_charges))
        }
        else{
            setShippingCharge(0)
        }
      }
    const PerfomaInvoice = (props) => {
        const gst = 0
        const ordertotal = props.buypopupctotal + shippingcharge
        // props.onHide()
        props.GeneratePerforma(shippingcharge,props,gst,props.buypopupctotal,value,vipdiscount)
    }
    const ProccedToCheckout = () => {
        history.push({
            pathname: "/checkout",
            state: {
                diamonddata:props.diamonddata,
                shippingcharge:shippingcharge,
                shippingvalue:value
            },
                // state: decompress(res.data.ProductDetails),
            })
    }
    return (
        <div>
            <Modal
                show={props.show}
                onHide={props.onHide}
                backdrop="static"
                keyboard={false}
                size="lg"
                // className={`${
                //     props.diamond_type === "N" ? "natTab" : "labTab"
                // }`}
                className={`natTab`}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.language.REQ_INVOICE_MSG}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mt-4">
                           <span className="pr-3 mr-3 border-right"> {props.language.CONFIRM_GOODS_POPUP_TOTAL_DIAMONDS} : {props.diamonddata.length} Pcs</span >
                           <span> {props.language.REQ_TOTAL_CARAT} : {numberWithCommas(props.totalcarat)}</span >
                        </div>
                        <div className="col-12">
                            <div
                                className="custom-scrollbar"
                                style={{ maxHeight: "322px" }}
                            >
                                <table className="table border-bottom">
                                    <thead>
                                        <tr className="bg-gray-100 sticky-top">
                                            <th align="center" className="text-center py-3"><p className="mb-2">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</p></th>
                                            <th className="text-center py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_DIAMOND_LOCATION} 
                                            </th>
                                            <th align="center" className="text-right py-3">
                                                {props.language.CONFIRM_GOODS_POPUP_OURPRICE} <br />
                                                $/CT
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.diamonddata.map((value => (
                                            <tr className={`${value.perfoma_no? "CanNotHoldDiv" : "" }`}>
                                                
                                            {value.diamondType === "PN" || value.diamondType === "PL"?<td>
                                               <div className={`row`}>
                                               
                                                   <div className="col-2 text-center p-0">
                                                        <img
                                                            className="img-fluid mr-2"
                                                            alt={value.shape}
                                                            src={toAbsoluteUrl(`/media/shape/svg_shape/parcel/${value.shape.toLowerCase()}.svg`)}
                                                            width="50px"
                                                            onError={e => {
                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                    `/media/shape/svg_shape/parcel/other.svg`
                                                                    )
                                                                }}
                                                        />
                                                   </div>
                                                   <div className="col-10 pl-2 p-0">
                                                        <div className="mb-1">
                                                            {value.perfoma_no?
                                                                <span className="CanNotHold">Proforma already generated please contact your sales executive</span>
                                                            :""}
                                                            <span className={`${value.diamondType}_diamond mr-3 small`}>
                                                                {value.diamondType}
                                                            </span>
                                                            <span className=" small">
                                                            {value.diamondType}-{value.stockID}
                                                            </span>
                                                            <span className=" text-capitalize small ml-2">
                                                            By: {value.parcelType}
                                                            </span>
                                                            <span className=" small ml-2">
                                                            {value.parcelLength?value.parcelLength.toFixed(2):"" }-{value.parcelWidth?value.parcelWidth.toFixed(2):"" } MM
                                                            </span>
                                                        </div>

                                                        <span className="text-dark font-weight-bold d-block">
                                                        {value.shape}{" "}
                                                        {value.weight?value.weight.toFixed(2):""}Ct.{" "}
                                                        {value.parcelPieces}Pcs.{" "}
                                                        {value.color}{" "}
                                                        {value.clarity}{" "}
                                                        </span>
                                                    
                                                    </div>
                                               </div>
                                           </td>:<td>
                                               <div className={`row`}>
                                               
                                                   <div className="col-2 text-center p-0">
                                                        <img className="img-fluid mr-2" alt={ value.shape } src={toAbsoluteUrl( `/media/shape/${value.shape.toLowerCase()}-no.png` )} onError={e => {
                                                            e.currentTarget.src = toAbsoluteUrl(
                                                                `/media/shape/noimage.jpg`
                                                            )
                                                        }} width="50px" style={{ backgroundColor: "#f4f4f4", }} />
                                                   </div>
                                                   <div className="col-10 pl-2 p-0">
                                                        <div className="mb-1">
                                                        {value.perfoma_no?
                                                    <span className="CanNotHold">Proforma already generated please contact your sales executive</span>
                                                :""}
                                                            <span
                                                                className={`${
                                                                    value.diamondType ===
                                                                    "N"
                                                                        ? value.isfancy
                                                                            ? "F"
                                                                            : "W"
                                                                        : value.isfancy
                                                                        ? "LF"
                                                                        : "L"
                                                                }_diamond mr-3 small`}
                                                            >
                                                                {value.diamondType ===
                                                                "N" ||value.diamondType ===
                                                                "W"
                                                                    ? value.isfancy
                                                                        ? "F"
                                                                        : "N"
                                                                    : value.isfancy
                                                                    ? "LF"
                                                                    : "L"}
                                                            </span>
                                                            
                                                            <span className="small mr-2">
                                                                {value.diamondType ===
                                                                "N"
                                                                    ? "N"
                                                                    : "L"}
                                                                -{value.stockID}
                                                            </span>
                                                            <span className="small">
                                                                {value.lab}-
                                                                {value.certificate}
                                                            </span>
                                                        </div>

                                                        <span className="text-dark font-weight-bold d-block">
                                                            {value.shape}{" "}
                                                            {value.weight}{" "}
                                                            {
                                                                value.color
                                                            }{" "}
                                                            {value.clarity}{" "}
                                                            {value.cut}{" "}
                                                            {value.polish}{" "}
                                                            {value.symmetry}{" "}
                                                            {
                                                                value.fluorescence
                                                            }{" "}
                                                        </span>
                                                    
                                                    </div>
                                               </div>
                                           </td>}
                                           <td className="text-center">
                                           {value.country} <br />
                                           </td>
                                           <td className={`text-right`}>
                                                <span className="font-weight-bold">${numberWithCommas(parseFloat(value["ourPrice"]))}</span ><br/>
                                                <span className="text-muted small">$/CT {numberWithCommas(parseFloat(value["ourRate"]))} <br /></span >
                                           </td>
                                       </tr>
                                        )))}

                                    </tbody>
                                </table>
                            </div>
                            <div className="row mt-5 m-0">
                                <div className="col-md-5">
                                    <div className={classes.root}>
                                        <FormControl component="fieldset" className={classes.formControl}>
                                            <FormLabel component="legend">{props.language.REQ_SELECT_SHIPPING}:</FormLabel>
                                            <RadioGroup
                                            aria-label="Select Shipping Method:"
                                            name="Shipping"
                                            className={classes.group}
                                            value={value}
                                            onChange={handleChange}
                                            >
                                                {/* <FormControlLabel value="40" control={<Radio />} label="FEDEX" />
                                                <FormControlLabel value="25" control={<Radio />} label="UPS" /> */}
                                                {convinienceobj && convinienceobj.fedex_charges != 0 && convinienceobj.ups_charges != 0 && convinienceobj.hk_delivery != "Y"?
                                                <>
                                                <FormControlLabel value="fedex" control={<Radio />} label="FEDEX" />
                                                <FormControlLabel value="ups" control={<Radio />} label="UPS" /></>
                                                :""}

                                                {convinienceobj && convinienceobj.fedex_charges != 0 && convinienceobj.ups_charges == 0 && convinienceobj.hk_delivery != "Y"?
                                                <>
                                                <FormControlLabel value="fedex" control={<Radio />} label="FEDEX" />
                                                </>
                                                :""}

                                                {convinienceobj && convinienceobj.ups_charges != 0 && convinienceobj.fedex_charges == 0 && convinienceobj.hk_delivery != "Y"?
                                                <>
                                                <FormControlLabel value="ups" control={<Radio />} label="UPS" />
                                                </>
                                                :""}

                                                {convinienceobj && convinienceobj.fedex_charges != 0 && convinienceobj.ups_charges != 0 && convinienceobj.hk_delivery == "Y"?
                                                <>
                                                <FormControlLabel value="fedex" control={<Radio />} label="FEDEX" />
                                                <FormControlLabel value="ups" control={<Radio />} label="UPS" />
                                                <FormControlLabel value="hkdelivery" control={<Radio />} label="HK DELIVERY" />
                                                </>
                                                :""}

                                                {convinienceobj && convinienceobj.fedex_charges == 0 && convinienceobj.ups_charges == 0 && convinienceobj.hk_delivery == "Y"?
                                                <>
                                                <FormControlLabel value="hkdelivery" control={<Radio />} label="HK DELIVERY" />
                                                </>
                                                :""}

                                                {convinienceobj && convinienceobj.fedex_charges != 0 && convinienceobj.ups_charges == 0 && convinienceobj.hk_delivery == "Y"?
                                                <>
                                                <FormControlLabel value="fedex" control={<Radio />} label="FEDEX" />
                                                <FormControlLabel value="hkdelivery" control={<Radio />} label="HK DELIVERY" />
                                                </>
                                                :""}

                                                {convinienceobj && convinienceobj.fedex_charges == 0 && convinienceobj.ups_charges != 0 && convinienceobj.hk_delivery == "Y"?
                                                <>
                                                <FormControlLabel value="ups" control={<Radio />} label="FEDEX" />
                                                <FormControlLabel value="hkdelivery" control={<Radio />} label="HK DELIVERY" />
                                                </>
                                                :""}
                                            </RadioGroup>
                                        </FormControl>
                                        
                                    </div>
                                    <div>{props.language.REQIN_POPUP_PAR1}</div>
                                </div>
                                
                                <div className="col-md-7">
                                    <div className="row border rounded bg-primary-o-20 py-5">
                                        <div className="col-12 font-size-h6 text-right d-flex justify-content-between">
                                            <span>{props.language.REQ_DIAMOND_DIAMOND_PRICE} :</span> <span>${numberWithCommas(props.buypopupctotal)}</span>
                                        </div>
                                        {shippingcharge?<div className="col-12 font-size-h6 mt-2 mb-2 text-right d-flex justify-content-between">
                                            <span>{props.language.REQ_DIAMOND_CONVINIENCE_FEE}:</span> <span>${numberWithCommas(shippingcharge)}</span>
                                        </div>:""}
                                        {vipdiscount?<div className="col-12 font-size-h6 mt-2 mb-2 text-right d-flex justify-content-between">
                                            <span>{props.language.INVOICE_SERVICE_CHARGE_DISCOUNT} :</span> <span className="text-danger">-${numberWithCommas(vipdiscount)}</span>
                                        </div>:""}
                                        <div className="col-12 font-size-h4 border-top mt-2 pt-2 text-right font-weight-bold d-flex justify-content-between">
                                            <span>{props.language.REQ_TOTAL_DIAMOND_PRICE} :</span>  <span>${numberWithCommas(props.buypopupctotal + shippingcharge - vipdiscount)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <pre>{JSON.stringify(props)}</pre> */}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-end">
                    <Button className="px-8 btn btn-text-dark btn-hover-light-dark" variant="secondary" onClick={() => ProccedToCheckout(props)}>
                        <CiCreditCard2 className="mr-2 font-size-h4"/>
                        {props.language.REQ_INVOICE_PAYVIACC}
                    </Button>
                    <Button disabled={props.isSubmitting} className="px-8" variant="primary" onClick={() => PerfomaInvoice(props)}>
                        <CiBank className="mr-2 font-size-h4"/>
                        {props.language.REQ_TOTAL_PAYBANKWIRE}
                        {props.loading && <span className="ml-1 spinner spinner-white"></span>}
                        <Backdrop className={classes.backdrop} open={props.openLoader} >
                            <div className="px-5 py-3 bg-white rounded shadow-sm d-flex justify-content-center align-items-center"><CircularProgress size="30px" color="secondary" /> <span className="pl-2 font-weight-bold text-dark">Processing..</span></div>
                        </Backdrop>
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
