/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {DiamondDetails} from "./DiamondDetails"
import { FaCheck } from 'react-icons/fa';
import { BiShieldQuarter } from 'react-icons/bi';
import axios from "axios"
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
import { loadStripe } from "@stripe/stripe-js";
let PUBLIC_KEY = process.env.REACT_APP_STRIPETEST;
if(process.env.REACT_APP_ENV === "production"){
    PUBLIC_KEY = process.env.REACT_APP_STRIPELIVE
}

const stripeTestPromise = loadStripe(PUBLIC_KEY);
export const Payment = (props) => {
    // console.log(props.userinfo.shipping_address?props.userinfo.shipping_address.replaceAll("<p>","").split("</p>"):"NOT","PRODPST")
    const [diamondttotal,setDiamondTotal] = useState(0)
    const [shippingcharge,setShippingCharge] = useState(0)
    const [cccharge,setCCCharge] = useState(0)
    const [vipdiscount] = useState(0)

    const [paymentgateway] = useState("stripe")
    useEffect(() => {
        // let total = 0
        // props.details.diamonddata.map(value => {
        //     total += parseFloat(value["our_price(USD)"]) 
        // })
        // setDiamondTotal(total)
        const userData = JSON.parse(localStorage.getItem("userData"))
        let confirm_goods_ids = []
        props.details.diamonddata.map(value => {
            confirm_goods_ids.push(value["conformGoodId"]) 
        })
        const finalData = {
            confirm_goods_ids:confirm_goods_ids,
            "shippingmethod":props.details.shippingvalue,
            "country":props.userinfo.country
        }
        axios({
            method: "get",
            url: `${NODE_API_URL}/EUR/getStripeInvoiceCalculation`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            params:finalData
        })
        .then(async function(res) {
            if(res && res.data && res.data.totalprice){
               setDiamondTotal(res.data.totalprice)
               setShippingCharge(res.data.shippingcharges)
               setCCCharge(res.data.cccharge)
            //    setVipDiscount(res.data.vip_discount)
            }
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    },[])
    const StripePayment = async() => {
        const stripe = await stripeTestPromise
        const userData = JSON.parse(localStorage.getItem("userData"))
        let confirm_goods_ids = []
        props.details.diamonddata.map(value => {
            confirm_goods_ids.push(value["conformGoodId"]) 
        })
        const finalData = {

            confirm_goods_ids:confirm_goods_ids,
            "shippingmethod":props.details.shippingvalue,
            "country":props.userinfo.country
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/EUR/stripeCheckoutSession`,
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data:finalData
        })
        .then(async function(res) {
            if(res && res.data && res.data.data?.id){
                const result = await stripe.redirectToCheckout({
                    sessionId: res.data.data?.id,
                  });
            }
        })
        .
        catch(error => {
          if(error?.response?.data?.message === "UnAuthorized!"){
            var cookies = document.cookie.split(";");
          for (var i = 0; i < cookies.length; i++) {
            if(cookies[i] && !cookies[i].includes("view")){
              var equals = cookies[i].indexOf("=");
              var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
              document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          }
          
          window.location.replace(`${BASE_URL}/logout`);
        }
        })
    }
    function numberWithCommas(x) {
        if(x){
            return parseFloat(x).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{
            return "0.00"
        }
    }
    const getaddress = () => {
            let shippingaddress = ""
            let billingaddress = ""
            if(props.userinfo && props.userinfo.id){
                if(props.userinfo.company_name_official){
                    shippingaddress += "<p><b>"
                    shippingaddress += props.userinfo.company_name_official
                    shippingaddress += "</b></p>"
                }
                if(props.userinfo?.name_atten){
                    shippingaddress += "<p><b>"
                    shippingaddress += props.userinfo?.name_atten
                    shippingaddress += "</b></p>"
                }
                if(props.userinfo?.shippingaddress.shipping_address_1){
                  shippingaddress += "<p>"
                  shippingaddress += props.userinfo?.shippingaddress.shipping_address_1 + ","
                  shippingaddress += "</p>"
                }
                if(props.userinfo?.shippingaddress.shipping_address_2){
                    shippingaddress += "<p>"
                    shippingaddress += props.userinfo?.shippingaddress.shipping_address_2 + ","
                    shippingaddress += "</p>"
                }
                if(props.userinfo?.shippingaddress.shipping_address_3){
                    shippingaddress += "<p>"
                    shippingaddress += props.userinfo?.shippingaddress.shipping_address_3 + ","
                    shippingaddress += "</p>"
                }
                shippingaddress += "<p>"
                shippingaddress += props.userinfo?.shippingaddress.shipping_city + ", " + props.userinfo?.shippingaddress.shipping_state + ", " + props.userinfo?.shippingaddress.shipping_zip_code + ", "
                shippingaddress += "</p>"
                if(props.userinfo.country){
                    shippingaddress += "<p>"
                    shippingaddress += props.userinfo.country
                    shippingaddress += "</p>"
                }
            
                
            
                if(props.userinfo.company_name_official){
                    billingaddress += "<p><b>"
                    billingaddress += props.userinfo.company_name_official
                    billingaddress += "</b></p>"
                }
                if(props.userinfo.address){
                  billingaddress += "<p>"
                  billingaddress += props.userinfo.address + ","
                  billingaddress += "</p>"
                }
                if(props.userinfo.secaddress){
                    billingaddress += "<p>"
                    billingaddress += props.userinfo.secaddress + ","
                    billingaddress += "</p>"
                }
                if(props.userinfo.addressline3){
                    billingaddress += "<p>"
                    billingaddress += props.userinfo.addressline3 + ","
                    billingaddress += "</p>"
                }
                billingaddress += "<p>"
                billingaddress += props.userinfo?.city + ", " + props.userinfo?.state + ", " + props.userinfo?.com_zip + ","
                billingaddress += "</p>"
                if(props.userinfo.country){
                    billingaddress += "<p>"
                    billingaddress += props.userinfo.country
                    billingaddress += "</p>"
                }
            }
            return{
                shippingaddress:shippingaddress,
                billingaddress:billingaddress
            }
    }
    return (
        <>
            <div className="row">
                <div className="col-md-8">
                    <div className="card border-0 custom-card gutter-b">
                        <div className="card-header bg-transparent border-bottom-0 px-2 py-4">
                            <div className="card-title font-weight-bold mb-0 font-size-h5">{props.language.CHECKOUT_BILLING_INFORMATION} <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body bg-white rounded pt-4">
                            {getaddress().billingaddress ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().billingaddress} } />: '-'}
                        </div>
                    </div>
                    <div className="card custom-card border-0 gutter-b">
                        <div className="card-header bg-transparent border-bottom-0 px-2 py-4">
                            <div className="card-title font-weight-bold mb-0 font-size-h5">{props.language.CHECKOUT_DELIVERY_INFORMATION} <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body bg-white rounded pt-4">
                            <div className="text-capitalize">
                                {/* {props.userinfo.shipping_address} */}
                                <div className="card-body bg-white rounded pt-4">
                            {getaddress().shippingaddress ?  <div className="invAddress" dangerouslySetInnerHTML={ {__html:  getaddress().shippingaddress} } />: '-'}
                                    </div>
                                <br />
                                <p><span><b>E</b>: {props.userinfo?.shippingaddress?.shipping_email}</span>  <span className="ml-md-10"><b>M</b>: {props.userinfo?.shippingaddress?.shipping_phone}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="card border-0 custom-card gutter-b">
                        <div className="card-header bg-transparent border-bottom-0 px-2 py-4">
                            <div className="card-title font-weight-bold mb-0 font-size-h5">{props.language.CHECKOUT_SHIPPING_METHOD}</div>
                        </div>
                        <div className="card-body bg-white rounded">
                            <p className="mb-0">{props.details.shippingvalue.toUpperCase()}</p>
                        </div>
                    </div>
                    <div className="card border-0 custom-card gutter-b">
                        <div className="card-header bg-transparent border-bottom-0 px-2 py-4">
                            <div className="card-title font-weight-bold mb-0 font-size-h5">{props.language.INVOICE_DETAIL_PAYMENT_METHOD}</div>
                        </div>
                        <div className="card-body bg-white pt-4">
                            <div className="pl-5 font-weight-bold">{props.language.CHECKOUT_DEBITCREDIT}</div>
                            <div className="creditcards pl-5 mt-2">
                                <div className="visa-card "></div>
                                <div className="master-card mx-1"></div>
                                <div className="mestro-card mx-1"></div>
                                <div className="aac-card mx-1"></div>
                                <div className="union-card mx-1"></div>
                            </div>
                            {/* <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="stripe" onClick={(e) => setPaymentGateWay(e.target.value)} checked={paymentgateway === "stripe"} className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="dummy_card" src={toAbsoluteUrl(`/media/General/dummy_card.png`)} />
                                    </div>
                                    <span className="text-right">Debit/Credit Card</span>
                                </div>
                            </label> */}
                            {/* <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="paypal" onClick={(e) => setPaymentGateWay(e.target.value)} checked={paymentgateway === "paypal"} className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="paypal" src={toAbsoluteUrl(`/media/General/paypal.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Paypal</span>
                                </div>
                            </label>
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="apple_pay" src={toAbsoluteUrl(`/media/General/Apple_Pay.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Apple pay</span>
                                </div>
                            </label>
                            <label className="payment_method" >
                                <div className="text-center">
                                    <input type="radio" name="paymenyType" value="" className="payment_method_input" />
                                    <div className="payment_method_box">
                                        <img className="img-fluid" alt="bankwire" src={toAbsoluteUrl(`/media/General/bankwire.png`)} />
                                    </div>
                                    <span className="font-weight-bold">Bank Wire</span>
                                </div>
                            </label> */}
                        </div>
                    </div>
                    <div className="card custom-card border-0 gutter-b">
                        <div className="card-header bg-transparent border-bottom-0 px-2 py-4">
                            <div className="card-title font-weight-bold mb-0 font-size-h5">{props.language.CHECKOUT_DIAMONDDETAILS}  <FaCheck className="text-primary ml-2" /></div>
                        </div>
                        <div className="card-body bg-white pt-4">
                            <DiamondDetails data={props.details.diamonddata} language={props.language}/>
                        </div>
                    </div> 
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card bg-white custom-card gutter-b">
                                <div className="card-body p-5">
                                   <div className="row">
                                        <div className="col-12 mb-2">
                                            <div className="mb-0 font-size-h5 font-weight-bold">{props.language.CHECKOUT_PRICE_DETAILS}<span className="small ml-2">({props.details.diamonddata.length} {props.language.CHECKOUT_ITEMS})</span></div>
                                        </div>
                                        <div className="col-12 d-flex mt-2 justify-content-between">
                                            <div> {props.language.INVOICE_DIAMOND_PRICE}: </div>
                                            <div> ${numberWithCommas(diamondttotal)} </div>
                                        </div>
                                       
                                        {shippingcharge?<div className="col-12 d-flex mt-2 justify-content-between">
                                            <div> {props.language.REQ_DIAMOND_CONVINIENCE_FEE}: </div>
                                            <div> ${numberWithCommas(shippingcharge)} </div>
                                        </div>:""}
                                        <div className="col-12 d-flex mt-2 justify-content-between">
                                            <div> {props.language.INVOICE_CREDIT_CARD_CHARGES}: </div>
                                            <div> ${numberWithCommas(cccharge)} </div>
                                        </div>
                                        {vipdiscount?<div className="col-12 d-flex mt-2 justify-content-between">
                                            <div> {props.language.INVOICE_SERVICE_CHARGE_DISCOUNT}: </div>
                                            <div className="text-danger"> -${numberWithCommas(vipdiscount)} </div>
                                        </div>:""}
                                   </div>
                                </div>
                                <div className="card-footer px-5 py-5">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-between font-weight-bold">
                                            <div className="mb-0 font-size-h5">{props.language.REQ_TOTAL_DIAMOND_PRICE}</div>
                                            <div className="mb-0 font-size-h5">${numberWithCommas(Math.floor((diamondttotal + shippingcharge + cccharge - vipdiscount)*100)/100)}</div>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        {props.language.CHECKOUT_PAR1} <a href="https://alpha.diamondsupload.com/privacy-policy">{props.language.CHECKOUT_PAR2}</a>, <a href="https://alpha.diamondsupload.com/term-condition">{props.language.CHECKOUT_PAR3}</a> {props.language.CHECKOUT_PAR4}                                      
                    </div>
                    <br/>
                    {
                        paymentgateway === "stripe"?<div className="col-12 text-center">
                                <button className="btn btn-primary px-md-30 px-20 font-weight-bold" onClick={() => StripePayment()}>{props.language.CHECKOUT_COMPLETE_PAYMENT}</button>                                      
                        </div>:""
                    }
                    <div className="col-12 text-center text-muted mt-4">
                        <BiShieldQuarter className="mr-2 font-size-h5" />{props.language.CHECKOUT_SAFENSECURE}
                    </div>
                </div>
            </div>
        </>
    );
};
