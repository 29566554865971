import React, { useState,useEffect } from "react";
import { SearchResult } from "./parcelSearchResult/searchResult/SearchResult";
import { ParcelTrackList } from "./parcelSearchResult/trackList/ParcelTrackList";
import { BsLightningFill,BsArrowLeftShort } from "react-icons/bs";
import { ConfirmGoodsParcel } from "../Popup/ConfirmGoodsParcel";
import {OverlayTrigger,Tooltip} from "react-bootstrap";
import axios from "axios"
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import { SwalSuccess, SwalWarn } from "../Popup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { IoMdClose } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Cookies from "js-cookie"

export const ParcelSearchResult = ({ userinfo, language,resultData,setResultData,setParcelSearchOpen,setParcelResultOpen,conversionrate,symbol }) => {
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalWarnOpenCtPcs,setSwalWarnOpenCtPcs] = useState(false)
    const [swalTrackListSuccess,setSwalTrackListSuccess] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    const [totalOrder, setTotalOrder] = useState("0");
    const [totalDiamonds, setTotalDiamonds] = useState("0");
    const [totalDollarCarat, setTotalDollarCarat] = useState("0");
    const [totalPrice, setTotalPrice] = useState("0");
    const [totalCarat, setTotalCarat] = useState("0");
    const [allResult,setAllResult] =useState([])
    const [trackListData,setTrackListData] =useState([])
    const [searchResultData,setSearchResultData] =useState([])
    const [buypopup,setBuyPopUp] = useState(false)
    const [deletePopup,setDeletePopUp] = useState(false)
    const [checkMatch,setCheckMatch] = useState(false)
    const [temp, setTemp] = useState(true)
    const [dimondType, setDiamondType] =useState('');
    useEffect(() => {
        if(window.location.pathname === "/parcel-diamond"){
            setDiamondType("PN")
        }
        if(window.location.pathname === "/lab-parcel-diamond"){
            setDiamondType("PL")
        }
    },[])

    const count = () =>{
        let temptotalorder = 0
        let temptotaldiamonds = 0
        let temptotalprice = 0
        //  let temptotaldollarcarat = 0
        let diamondcarat = 0
        let tempTrackListtotalorder = 0
        let tempTrackListtotaldiamonds = 0
        let tempTrackListtotalprice = 0
        //  let tempTrackListtotaldollarcarat = 0
        let diamondTrackListcarat = 0
        searchResultData.map(value => {
            // const finddata = value.find(val => value.original.id.toString() === val.id.toString())
            temptotalorder += 1
            temptotaldiamonds += parseFloat(value.original.pcs)
            temptotalprice += parseFloat(value.original.totalPrice)
            diamondcarat += parseFloat(value.original.carat)
            // temptotaldollarcarat += parseFloat(value.original.totalPrice/value.original.carat)
            return {
                // ...finddata,
                diamondcarat:diamondcarat,
                temptotalprice:temptotalprice,
                temptotaldiamonds:temptotaldiamonds,
                // temptotaldollarcarat:temptotaldollarcarat
            }
        })

        trackListData.map(value => {
            // const finddata = value.find(val => value.original.id.toString() === val.id.toString())
            tempTrackListtotalorder += 1
            tempTrackListtotaldiamonds += parseFloat(value.original.pcs)
            tempTrackListtotalprice += parseFloat(value.original.totalPrice)
            diamondTrackListcarat += parseFloat(value.original.carat)
            // tempTrackListtotaldollarcarat += parseFloat(value.original.totalPrice/value.original.carat)
            return {
                // ...finddata,
                diamondTrackListcarat:diamondTrackListcarat,
                tempTrackListtotalprice:tempTrackListtotalprice,
                tempTrackListtotaldiamonds:tempTrackListtotaldiamonds,
                // tempTrackListtotaldollarcarat:tempTrackListtotaldollarcarat
            }
        })
        setTotalOrder(parseFloat(temptotalorder) + parseFloat(tempTrackListtotalorder))
        setTotalDiamonds(parseFloat(temptotaldiamonds) + parseFloat(tempTrackListtotaldiamonds))
        setTotalPrice(parseFloat(temptotalprice) + parseFloat(tempTrackListtotalprice))
        setTotalCarat(parseFloat(diamondcarat) + parseFloat(diamondTrackListcarat))
        setTotalDollarCarat(tempTrackListtotalprice || temptotalprice !== 0 ?  parseFloat(tempTrackListtotalprice + temptotalprice) / parseFloat(diamondcarat + diamondTrackListcarat) : "00")
        const NewData = { data: [...searchResultData, ...trackListData] };
        setAllResult(NewData)
        // console.log("searchResultData",searchResultData)
        // console.log("trackListData",trackListData)
        // console.log("NewData",NewData)
    }

    useEffect(() => {
        count()
    },[searchResultData,trackListData])

    // console.log("tracklistdata",trackListData)
    const buyDiamond = () =>{
        if(searchResultData.length === 0 && trackListData.length === 0){
            alert("Please Select Diamonds")
            return
        }
        
        let showBuyPopUp = true; // Default to true
        const combinedData = [...trackListData, ...searchResultData];

        // Apply the condition check for the merged data
        checkCaratLimits(combinedData, setAlertMessage, setSwalWarnOpenCtPcs);

        // Outside the function, update setBuyPopUp based on the flag
        if (showBuyPopUp) {
            setBuyPopUp(true);
        }
        
        function checkCaratLimits(records, setAlertMessage, setSwalWarnOpenCtPcs) {
            let conditionMet = false;
            let maxCaratConditionMet = false;

            for (const record of records) {
                // if (record.original.carat < 0.05) {
                //     conditionMet = true;
                //     setAlertMessage(`Minimum order quantity is 0.05 carats. Please adjust your Carats. Contact us for assistance. Thank you.`);
                //     setSwalWarnOpenCtPcs(true);
                //     break;
                // }
        
                if (record.original.carat > 200) {
                    maxCaratConditionMet = true;
                    setAlertMessage(`Maximum order quantity is 200.00 carats. Please adjust your Carats. Contact us for assistance. Thank you.`);
                    setSwalWarnOpenCtPcs(true);
                    break;
                }
            }
        
            // If maxCaratConditionMet is true, set the flag based on that condition
            // Otherwise, set the flag based on the general conditionMet
            showBuyPopUp = maxCaratConditionMet ? !maxCaratConditionMet : !conditionMet;
        }

       
        if (searchResultData.length !== 0){

            for(let i = 0;i < trackListData.length;i++){
                let shapematch = false
                let lengthmatch = false
                let widthmatch = false
                let colormatch = false
                let claritymatch = false

                let trackshape = trackListData[i].original.C_Shape
                let searchshape = searchResultData[0].original.C_Shape ? searchResultData[0].original.C_Shape : "ROUND"
                if(trackshape === searchshape){
                    shapematch = true
                }

                let tracklengthmm = trackListData[i].original.mm_to
                let searchlengthmm = searchResultData[0].original.mm_to
                if(tracklengthmm === searchlengthmm){
                    lengthmatch = true
                }

                let trackwidthmm = trackListData[i].original.mm_from
                let searchwidthmm = searchResultData[0].original.mm_from
                if(trackwidthmm === searchwidthmm){
                    widthmatch = true
                }
                
                let trackcolor= trackListData[i].original.color
                let searchcolor= searchResultData[0].original.color
                if(trackcolor === searchcolor){
                    colormatch = true
                }

                let trackclarity= trackListData[i].original.clarities
                let searchclarity= searchResultData[0].original.clarities
                if(trackclarity === searchclarity){
                    claritymatch = true
                }
                
                if(shapematch && lengthmatch && widthmatch && colormatch && claritymatch){
                    setSwalWarnOpen(true)
                    setAlertMessage(`you are buying same parcel category from track list, are you sure you want to buy ?`)
                    setCheckMatch(false)
                    return
                }
            }
        }
        if (showBuyPopUp) {
            setBuyPopUp(true);
        }
        // console.log("here")
    }
    useEffect(() => {
        if(checkMatch){
            setSwalWarnOpen(false)
            setBuyPopUp(true)
            setCheckMatch(false)
            // console.log("searchResultData")
        }
    },[checkMatch])
    // console.log(symbol,conversionrate)
    const addTracklist = () =>{
        if(searchResultData.length === 0 ){
            alert("Please Select Diamonds")
            return
        }
        const finalData = {
            "user_id":userinfo.id,
            "c_shape": searchResultData[0].original.C_Shape ? searchResultData[0].original.C_Shape : "ROUND",
            "weight": searchResultData[0].original.carat,
            "avgweight": searchResultData[0].original.avgweight,
            "dollarPerCarat": searchResultData[0].original.dollarPerCarat,
            "sumprice":searchResultData[0].original.sumprice,
            // "totalPrice": searchResultData[0].original.totalPrice,
            "sieve_mm_id_from": `${searchResultData[0].original.sieve_mm_id_from}`,
            "sieve_mm_id_to": `${searchResultData[0].original.sieve_mm_id_to}`,
            "sieve_from": `${searchResultData[0].original.sieve_from}`,
            "sieve_to": `${searchResultData[0].original.sieve_to}`,
            "mm_to":`${searchResultData[0].original.mm_to}`,
            "mm_from":`${searchResultData[0].original.mm_from}`,
            // "clarities":searchResultData[0].original.clarities,
            // "color":searchResultData[0].original.color,
            "clarity_id":  searchResultData[0].original.clarities_id,
            "color_id": searchResultData[0].original.color_id,
            "pieces": searchResultData[0].original.pcs,
            // "country": userinfo.country,
            "diamond_type":dimondType,
            "order_type":searchResultData[0].original.type
        }
        axios({
            method: "POST",
            url: `${NODE_API_URL}/parcel/insert-tracklist-diamond`,
            headers: {
                "Content-Type": "application/json",
                 "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
            },
            data: finalData,
        })
            .then((res) => {
                if (res && res.data) {
                    // alert("Stone tracklisted")
                    setSwalTrackListSuccess(true)
                    setAlertMessage("Stone has been added in tracklist !!")
                    setTemp(!temp)
                    setResultData([])
                //    console.log(res.data)
                //    setStoneData(res.data)
                }
            })
            .catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
    }

    const swalCloseFn = () =>{
        setSwalWarnOpen(false) 
        setSwalWarnOpenCtPcs(false) 
        // setCheckMatch(false)
    }

    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const deselectAll = () => {
        console.log("hehe",trackListData)
        console.log("hehe",searchResultData)
        setTrackListData([])
        setSearchResultData([])
      };

      const DeleteSwal = () => {
        // setSemiAprroveModal(true)
        if(searchResultData.length > 0){
            setSwalWarnOpenCtPcs(true)
            setAlertMessage("Please select tracklisted stones only !!")
            return
        }
        if(trackListData.length > 0){
            setAlertMessage("Are you sure want to Delete !!")
            setDeletePopUp(true)
        } else{
            setAlertMessage("Please select record for delete !!")
        }
    }

    const DeleteSelected = () => {
        if(trackListData){
            let parcel_id = []
            trackListData.map(value => {
                parcel_id.push(value.original.parcel_id)
            })
            axios({
                method: "delete",
                url: `${NODE_API_URL}/parcel/deleteParcelTracklist`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                data: {
                    parcel_id:parcel_id,
                    "user_id":userinfo.id
                },
            }).then(res => {
                if(res && res.data){
                    setDeletePopUp(false)
                    setTimeout(() => { 
                        setTemp(!temp)
                    }, 500);
                    // window.location.reload(true)
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
    }

    return (
        <>
            <ConfirmGoodsParcel userinfo={userinfo} show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={allResult} totalOrder={totalOrder} totalDiamonds={totalDiamonds} totalPrice={totalPrice} totalCarat={totalCarat} searchResultData={searchResultData} trackListData={trackListData} language={language} dimondType={dimondType} symbol={symbol} conversionrate={conversionrate}/> 
            <div className="parcelResult">
                <div className="row">
                    <div className="col-12">
                        <div className="card card-custom shadow-none">
                            { window.innerWidth > 700 ? 
                              null
                            :
                                <div className="card-header p-5 text-right sticky-top"style={{top:"50px"}}>
                                    <span onClick={()=>{setParcelResultOpen(false); setParcelSearchOpen(true);}}><BsArrowLeftShort /> Back to Search</span> 
                                </div>
                            }
                            {(searchResultData.length === 0 && trackListData.length === 0)?
                                <div className="card-header flex-nowrap text-nowrap align-items-center">
                                    <div className="card-label font-weight-bold font-size-h5 text-primary">{language.PARCEL_DIAMONDS_SEARCH_RESULT}</div>
                                </div>
                            :
                            <div className="card-header custom-scrollbar flex-nowrap text-nowrap selecetedHeader pl-10">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <button
                                            type="button"
                                            className="btn btn-primary mr-2 px-8 text-nowrap"
                                            onClick={()=>buyDiamond()}
                                        >
                                            <BsLightningFill /> {language.SEARCHLIST_BUYDIAMONDBTN}
                                        </button>
                                        {trackListData.length === 0 && (resultData.length !== 0 && !resultData.parcel_id)? 
                                            <OverlayTrigger
                                                placement="top"
                                                className="text-capitalize"
                                                overlay={<Tooltip id="tooltip">Click to Tracklist</Tooltip>}
                                            >
                                                <button className="btn btn-hover-light-dark" onClick={()=>addTracklist()}>
                                                    <SVG className="ol mr-2" src={toAbsoluteUrl("/media/svg/icons/Custom/Track.svg")} height="18px" width="18px" /> Track
                                                </button>
                                            </OverlayTrigger> 
                                        : " "}
                                           
                                        {trackListData.length > 0  ?  <button type="button" className="btn btn-hover-light-dark mr-1"  onClick={() => DeleteSwal()}><FaTrashAlt className='text-dark'/> Delete</button> : ""  }
                                        
                                    </div>
                                    <div className="card-toolbar flex-nowrap">
                                        <div>
                                            <span className="selected-diamond-box mx-2">
                                                <span className='cursor-pointer' ><IoMdClose onClick={deselectAll} className='text-dark'/></span> {totalOrder || "00"} {language.DIAMOND_COMPARISION_SELDIAMONDS} | {language.SEARCHLIST_CARAT} : {numberWithCommas(parseFloat(parseFloat(totalCarat).toFixed(2) || "00"))} | Amount : 
                                                    {conversionrate !== 1 ?
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip">
                                                                    ${(totalPrice).toLocaleString() || "00"}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span> {symbol}{numberWithCommas(parseFloat(totalPrice * conversionrate)) || "00"}</span>
                                                        </OverlayTrigger>
                                                    : <>{symbol}{numberWithCommas(parseFloat(totalPrice * conversionrate)) || "00"}</>}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            
                        </div>
                    </div>
                    <SwalWarn show={deletePopup} message={alertMessage} onHide={trackListData.length > 0 ?  () => setDeletePopUp(false) : ""} onClose={() => setDeletePopUp(false)} responseOnOk={trackListData.length > 0 ? () => DeleteSelected(): ""}/> 
                    <SwalWarn show={swalWarnOpen} message={alertMessage} onHide={() => setSwalWarnOpen(false)} onClose={() => swalCloseFn()} responseOnOk={()=> setCheckMatch(true)}/> 
                    <SwalWarn show={swalWarnOpenCtPcs} message={alertMessage} onClose={() => swalCloseFn()} /> 
                    <SwalSuccess show={swalTrackListSuccess} message={alertMessage} onClose={() => setSwalTrackListSuccess()} /> 
                    {/* {resultData.length !== 0 ? */}
                        <div className="col-12 pb-5 border-bottom">
                            <SearchResult  symbol={symbol} conversionrate={conversionrate} language={language} userinfo={userinfo} setSearchResultData={setSearchResultData} resultData={resultData} dimondType={dimondType} />
                        </div> 
                    {/* :""} */}
                    <div className="col-12">
                        <ParcelTrackList symbol={symbol} conversionrate={conversionrate} language={language} userinfo={userinfo} setTrackListData={setTrackListData} temp={temp} dimondType={dimondType}  />
                    </div>
                </div>
            </div>
        </>
       
    );
};
