import React, {useMemo,useState,useEffect} from "react";
import { useLocation,useHistory } from "react-router";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCardImage, BsFillCameraVideoFill ,BsLightningFill } from "react-icons/bs";
import { FaAward, FaThList, FaColumns } from "react-icons/fa";
// import { Box } from "@material-ui/core";
// import { Collapse } from "@material-ui/core";
import { ImagePopup } from "../widgets/tiles/ImagePopUp";
import { VideoPopup } from "../widgets/tiles/VideoPopUp";
import { FETCH_WISHLIST } from "../../../env_config";
import { ConfirmGoods,HoldPopup,SwalWarn,SwalError } from "../Popup";
import axios from "axios";
import {
   makeStyles,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Checkbox,
    TableSortLabel,
} from "@material-ui/core";
// import StockAPI from '../detailPage/DetailData.json'
import { FaArrowAltCircleRight, FaArrowCircleDown } from "react-icons/fa";
import MuiExpandedDetais from "./MuiExpandedDetails";
import PropTypes, { symbol } from 'prop-types';
import CertificateLink from "../CertificateLink";


// StockAPI = StockAPI.slice(0,25)
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
        },
    },
});
function numberWithCommas(x) {
    return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function GetRatio(row) {
    let $ratioval
    if (row.C_Shape != 'ROUND') {
        if (row.C_Length >= row.C_Width) {
            $ratioval = (row.C_Length / row.C_Width).toFixed(2);
        } else if (row.C_Length < row.C_Width) {
            $ratioval = (row.C_Width / row.C_Length).toFixed(2);
        } else if (row.C_Shape == 'HEART') {
            $ratioval = (row.C_Length / row.C_Width).toFixed(2);
        } else {
            $ratioval = '-';
        }
    } else {
        $ratioval = '-';
    }
    return $ratioval
}
function Row(props) {
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const { row ,isItemSelected,handleClick,labelId, conversionrate, symbol,wishlistdata,setUpdateWishlist,language,BuyDiamond,HoldDiamond} = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <TableRow 
                className={classes.root}
                hover
                // onClick={(event) => handleClick(event, row.ProductId)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={isItemSelected}
            >
                <TableCell padding="checkbox" align="center">
                    <Checkbox
                        checked={isItemSelected}
                        className="py-0"
                        inputProps={{ 'aria-labelledby': labelId }}
                        onClick={(event) => handleClick(event, row.id)}
                    />
                </TableCell>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <FaArrowCircleDown
                                className="text-primary"
                                title="Collapse"
                            />
                        ) : (
                            <FaArrowAltCircleRight
                                className="text-primary"
                                title="Expand"
                            />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell scope="row" className="text-nowrap">
                    <span>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {
                                    row.availability === 'Memo' || row.availability === 'Hold'
                                        ? `Business Process`
                                        : row.availability === "Guaranteed"
                                        ? `Guaranteed`
                                        : row.availability === "Not Available"
                                        ? `Not Available`
                                        : row.availability === "Subject to Prior sale"
                                        ? `Subject to Prior sale`
                                        : `Unknown`
                                    }
                                </Tooltip>
                            }
                        >
                            <img
                                className="img-fluid availIco mr-2"
                                alt={row.availability}
                                src={toAbsoluteUrl(`/media/svg/${
                                    row.availability === 'Memo' || row.availability === 'Hold'
                                        ? `availability_primary.svg`
                                        : row.availability === "Guaranteed"
                                        ? `availability_success.svg`
                                        : row.availability === "Not Available"
                                        ? `availability_danger.svg`
                                        : row.availability === "Subject to Prior sale"
                                        ? `availability_warning.svg`
                                        : `availability_blank_white.svg`
                                }`)}
                            />
                           
                        </OverlayTrigger>
                    </span>
                   <span className="text-primary"> {row.diamond_type}-{row.id}</span >
                </TableCell>
                <TableCell align="center">
                  {row.country ? (
                    <OverlayTrigger
                        placement="top"
                        className="text-capitalize"
                        overlay={<Tooltip id="tooltip">{row.country}</Tooltip>}
                    >
                        <img
                            className="img-fluid flagIco mr-4"
                            alt={row.country}
                            src={toAbsoluteUrl(`/media/map/${row.country.toLowerCase()}.png`)}
                        />
                    </OverlayTrigger>
                    ) : (
                        null
                    )}
                </TableCell>
                <TableCell align="center" className="text-nowrap">
                    <div className="d-flex justify-content-between">
                        {row.aws_image ? (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">Image</Tooltip>
                                    }
                                >
                                    <ImagePopup
                                        Image={row.aws_image}
                                        Shape={row.C_Shape}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={row.aws_image}><BsCardImage/></a> */}
                                </OverlayTrigger>
                            </span>
                        ) : (
                            <span style={{ width: "27px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                        {row.video ? (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">Video</Tooltip>
                                    }
                                >
                                    <VideoPopup
                                        ProductId={row.id}
                                        WLDiamondType={row.diamond_type}
                                        Certi_No={row.Certi_NO}
                                        Video={row.video}
                                    />
                                    {/* <a className="text-primary mx-2 text-center" href={row.video}><BsFillCameraVideoFill/></a> */}
                                </OverlayTrigger>
                            </span>
                        ) : (
                            <span style={{ width: "27px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                        {row.Certi_NO && user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Certificate
                                        </Tooltip>
                                    }
                                >
                                    
                                    <CertificateLink Certificate_link={row.Certi_link} lab={row.Lab} certificate={row.Certi_NO} useIcon hideLabName className={`mx-2 text-primary text-left`} />
                                </OverlayTrigger>
                            </span>
                        ) : (
                            <span style={{ width: "27px" }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                        )}
                    </div>
                </TableCell>
                <TableCell align="center">
                  <OverlayTrigger
                      placement="top"
                      overlay={
                          <Tooltip id="tooltip">
                              {row.C_Shape}
                          </Tooltip>
                      }
                  >
                    <SVG width="25px" height="25px" src={toAbsoluteUrl(`/media/shape/svg_shape/${row.C_Shape.toLowerCase()}.svg`)} />
                  </OverlayTrigger>
                </TableCell>
                <TableCell align="left">{row.C_Weight.toFixed(2)}</TableCell>
                <TableCell align="center">
                  <OverlayTrigger placement="top" overlay=
                        {<Tooltip id="tooltip" >
                            {row.C_Color ==="fancy" ? ( <> {row.f_intensity} {row.f_overtone} {row.f_color} </> ) : ( row.C_Color )}
                        </Tooltip>} >
                        <span className="d-inline-block text-truncate text-dark" style={{ maxWidth: "80px"}}>
                            {row.C_Color ==="fancy" ? ( <> {row.f_intensity} {row.f_overtone} {row.f_color} </> ) : ( row.C_Color )}
                        </span>
                  </OverlayTrigger>
                </TableCell>
                <TableCell align="center">{row.C_Clarity}</TableCell>
                <TableCell align="center">{row.C_Cut ? row.C_Cut : "-"}</TableCell>
                <TableCell align="center">{row.C_Polish}</TableCell>
                <TableCell align="center">{row.C_Symmetry}</TableCell>
                <TableCell align="center">{row.C_Fluorescence}</TableCell>
                <TableCell align="center">{row.Lab}</TableCell>
                <TableCell className="text-nowrap" align="center">
                    {
                        symbol !=="$" ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(row.OurRate)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(row.OurRate * conversionrate)}</span>
                            </OverlayTrigger>
                        </> :
                        <span> {symbol}{numberWithCommas(row.OurRate * conversionrate)}</span>
                    }
                </TableCell>
                <TableCell className="text-nowrap" align="center">
                    {
                         symbol !=="$" ? <>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip">
                                        ${numberWithCommas(row.OurPrice)}
                                    </Tooltip>
                                }
                            >
                                <span> {symbol}{numberWithCommas(row.OurPrice * conversionrate)}</span>
                            </OverlayTrigger>
                        </>:
                        <span> {symbol}{numberWithCommas(row.OurPrice * conversionrate)}</span>
                    }
                </TableCell>
                <TableCell align="center">{row.discount_main ? row.discount_main : "0.00"}%</TableCell>
                <TableCell align="center">{GetRatio(row)} </TableCell>
                <TableCell align="center" className="text-nowrap">
                    {row.C_Length?row.C_Length.toFixed(2):"0.00"}*{row.C_Width?row.C_Width.toFixed(2):"0.00"}*{row.C_Depth?row.C_Depth.toFixed(2):"0.00"}
                </TableCell>
                <TableCell align="center">{row.C_TableP.toFixed(2)}%</TableCell>
                <TableCell align="center">{row.C_DefthP.toFixed(2)}%</TableCell>
                <TableCell align="center">
                    {row.Certi_NO && user.CustomerType !=="7" && user.CustomerType !=="11" ? (
                        
                        <CertificateLink Certificate_link={row.Certi_link} lab={row.Lab} certificate={row.Certi_NO} hideLabName className={`mx-2 text-primary text-left`} />
                    ) : (
                        "-"
                    )}
                </TableCell>
            </TableRow>
            <MuiExpandedDetais row={row} open={open} conversionrate={conversionrate} symbol={symbol} wishlistdata={wishlistdata} setUpdateWishlist={setUpdateWishlist} language={language} handleClick={handleClick} totalinrcar={props.totalinrcar} totalourprice={props.totalourprice} diamondselected={props.diamondselected} buypopup={props.buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond}/>
        </React.Fragment>
    );
}
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
   stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort ,symbol, language} = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    const headCells = [
        { id: '', numeric: false, disablePadding: true, label: '' },
        { id: 'id', numeric: false, disablePadding: true, label: language.SEARCHLIST_STOCKID },
        { id: 'country',  numeric: false, disablePadding: false, label: language.SEARCHLIST_LOCATION  },
        { id: 'aws_image',  numeric: false, disablePadding: false, label: language.SEARCHLIST_MEDIA },
        { id: 'C_Shape',  numeric: false, disablePadding: false, label: language.SEARCHLIST_SHAPE },
        { id: 'C_Weight',  numeric: false, disablePadding: false, label: language.SEARCHLIST_CARAT },
        { id: 'C_Color',  numeric: false, disablePadding: false, label: language.SEARCHLIST_COLOR },
        { id: 'C_Clarity',  numeric: false, disablePadding: false, label: language.SEARCHLIST_CLARITY },
        { id: 'C_Cut',  numeric: false, disablePadding: false, label: language.SEARCHLIST_CUT },
        { id: 'C_Polish',  numeric: false, disablePadding: false, label: language.SEARCHLIST_POL },
        { id: 'C_Symmetry',  numeric: false, disablePadding: false, label: language.SEARCHLIST_SYMM },
        { id: 'C_Fluorescence',  numeric: false, disablePadding: false, label: language.SEARCHLIST_FLOU },
        { id: 'Lab',  numeric: false, disablePadding: false, label: language.SEARCHLIST_LAB },
        { id: 'OurRate', numeric: true, disablePadding: false, label: `${symbol === "₹"?"INR":symbol}/CT` },
        { id: 'OurPrice', numeric: true, disablePadding: false, label: language.SEARCHLIST_OURPRICE },
        { id: 'discount_main',  numeric: false, disablePadding: false, label: language.SEARCHLIST_DISC },
        { id: 'Ratio',  numeric: false, disablePadding: false, label: language.SEARCHLIST_RATIO },
        { id: 'C_Length',  numeric: false, disablePadding: false, label: language.SEARCHLIST_MEAS },
        { id: 'C_TableP',  numeric: false, disablePadding: false, label: language.SEARCHLIST_TABLE },
        { id: 'C_DefthP',  numeric: false, disablePadding: false, label: language.SEARCHLIST_DEPTH },
        { id: 'Certi_NO', numeric: false, disablePadding: false, label: language.SEARCHLIST_CERTIFICATE },
      ];
    return (
      <TableHead className="searchTableHeader">
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              className="text-nowrap"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
export const Search = ({conversionrate,symbol,language,isprofilecomplete}) => {
    const location = useLocation();
    const history = useHistory()
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('OurPrice');
    const [selected, setSelected] = React.useState([]);
    const isSelected = (name) => selected.indexOf(name) !== -1;
    // console.log(conversionrate,"conversionrate")
    const stoneCount = useMemo(()=>  localStorage.getItem("stoneCount"), [])
    const userData = React.useMemo(() => localStorage.getItem("userData"), []);
    const user = JSON.parse(userData);
    const [swalWarnOpen,setSwalWarnOpen] = useState(false)
    const [swalErrorOpen,setSwalErrorOpen] = useState(false)
    const [alertMessage,setAlertMessage] = useState()
    
    const [totalcarat,setTotalCarat] = useState(0)
    const [totalinrcar,setTotalInrCar] = useState(0)
    const [totalourprice,setTotalOurPrice] = useState(0)
    const [diamondselected,setDiamondSelected] = useState([])
    const [diamondcount,setDiamondcount] = useState(0)
    const [buypopup,setBuyPopUp] = useState(false)
    const [holdpopup,setHoldPopUp] = useState(false)
    const [buypopupcinr,setBuyPopUpInr] = useState(0)
    const [buypopupctotal,setBuyPopUpTotal] = useState(0)
    const [StockAPI, setStockAPI] = useState(location.state);
    // console.log(location.state,"location.state")
    useEffect(() => {
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        selected.map(value => {
            const finddata = StockAPI.find(element => element.id.toString() === value.toString())
            if(finddata){
                temptotalcarat += finddata.C_Weight
                temptotalinrcar += (selected.length>0?finddata.OurRate/selected.length:0)
                temptotalourprice += finddata.OurPrice
            }
        })
        setTotalCarat(temptotalcarat)
        setTotalInrCar(selected.length>0?(temptotalourprice/temptotalcarat * conversionrate):0)
        setTotalOurPrice(temptotalourprice * conversionrate)
    },[selected])
    // console.log(buypopupctotal,"buypopupctotal")
    const [updatewishlist,setUpdateWishlist] = useState()
    const [wishlistdata,setWishlistData] = useState([])
    useEffect(() => {
        const finalData = {
            user_id:user.CustomerId
        }
        axios({
            method: "POST",
            url: FETCH_WISHLIST,
            headers: { "Content-Type": "application/json" },
            data: finalData,
        }).then(res => {
            if(res && res.data){
                // setData(res.data)
                // console.log(res.data,"res.data")
                setWishlistData(res.data[0])

            }
        }).catch(err => {
        })
        setStockAPI(location.state)
    },[updatewishlist,location.state])
    // console.log(wishlistdata,"wishlistdata")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    // console.log(rows.length, "rows");
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const DetailSemiAprrove = () => {
        // setSemiAprroveModal(true)
        setSwalWarnOpen(true)
        setAlertMessage("Your account is under Lite Mode, to get further access please contact your sales representative. Thank you")
    }
    const profileSwalBuy = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to buy more diamonds")
    }
    const profileSwalHold = () => {
        // setSemiAprroveModal(true)
        setSwalErrorOpen(true)
        setAlertMessage("Please complete your profile to hold more diamonds")
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
  const ChangeView = () => {
      history.push({
        pathname: "/search-grid",
        state: stableSort(StockAPI, getComparator(order, orderBy)),
            // state: decompress(res.data.ProductDetails),
        });
    }
  const modifiedSearch = () => {
    const searchLink = StockAPI[0].diamond_type === "L" ? "/labsearchdiamond" : "/searchdiamond" 
      history.push({
        pathname: searchLink,
        state: StockAPI,
            // state: decompress(res.data.ProductDetails),
        });
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = StockAPI.map((n) => n.id);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
      };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
    
        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
          );
        }
    
        setSelected(newSelected);
      };
      const BuyDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.country === "INDIA"){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setDiamondcount(diamondcount)
        setBuyPopUp(true)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
      const HoldDiamond = (selected) => {
        // console.log(selected,"BuyDiamond")
        if(selected.length === 0){
            alert("Please Select Diamonds")
            return
        }
        // console.log(selected,"selected")
        let temptotalcarat = 0
        let temptotalinrcar = 0
        let temptotalourprice = 0
        let diamondcount = 0
       const selectedvalues = selected.map(value => {
            const finddata = StockAPI.find(val => value.toString() === val.id.toString())
            if(finddata.country === "INDIA" && finddata.can_hold === 1){
                diamondcount += 1
                temptotalcarat += finddata.C_Weight
                temptotalourprice += finddata.OurPrice
                temptotalinrcar += (diamondcount>0?finddata.OurRate/diamondcount:0)
            }
            return {
                ...finddata,
                temptotalcarat:temptotalcarat,
                temptotalinrcar:temptotalinrcar,
                temptotalourprice:temptotalourprice
            }
        })
        // console.log(temptotalinrcar,"temptotalinrcar")
        setDiamondSelected(selectedvalues)
        setHoldPopUp(true)
        setDiamondcount(diamondcount)
        setBuyPopUpInr(temptotalcarat > 0 ?temptotalourprice/temptotalcarat:0)
        setBuyPopUpTotal(temptotalourprice)
        // console.log(selectedvalues,"selectedvalues")
      }
    return (
        <div className={`card card-custom card-sticky gutter-b ${StockAPI[0].diamond_type === "L" ? "labTab" : "natTab"}`}>
          <div className="card-header py-3 mb-3">
              <div className="card-title align-items-start flex-column">
                  <span className="card-label font-weight-bolder text-dark">{stoneCount}  {language.SEARCHLIST_DIAMONDSFOUND} </span>
                  <span className="text-muted font-weight-bold font-size-sm">
                      {language.SEARCHLIST_TOTALDIAMONDSELECTED}: {selected.length} {language.SEARCHLIST_DIAMONDS}  |  {language.SEARCHLIST_TOTALCARAT} :{totalcarat.toFixed(2)}  |  Total {symbol === "₹"?"INR":symbol}/CT: {symbol}{totalinrcar.toFixed(2)}  |  {language.SEARCHLIST_TOTALPAYABLE}: {symbol}{totalourprice.toFixed(2)}
                  </span>
              </div>
              <div className="card-toolbar flex-md-nowrap">
                    {/* {selected.length >= 4 && !isprofilecomplete ?(
                        <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => profileSwalBuy() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                    ):(
                        <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => (user.CustomerType ==="7") ? BuyDiamond(selected) : DetailSemiAprrove() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                    )} 
                    {selected.length >= 4 && !isprofilecomplete ?(
                        <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => profileSwalHold() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                    ):(
                        <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => (user.CustomerType ==="7") ? HoldDiamond(selected) : DetailSemiAprrove() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                    )} */}
                  <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? BuyDiamond(selected) : DetailSemiAprrove() }> <BsLightningFill />{language.SEARCHLIST_BUYDIAMONDBTN}</button>
                  <button type="button" className="btn btn-light mr-2 text-nowrap" onClick={() => (user.CustomerType !=="7" && user.CustomerType !=="11") ? HoldDiamond(selected) : DetailSemiAprrove() }>{language.SEARCHLIST_HOLDDIAMONDBTN}</button>
                  <button type="button" className="btn btn-light-primary mr-2 text-nowrap" onClick={(e) => modifiedSearch()}>{language.SEARCHLIST_MODIFYDIAMONDBTN}</button>
                  {/* <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap"><FaThList /></button> */}
                  <button type="button" className="btn btn-icon btn-light-primary mr-2 text-nowrap" onClick={(e) => ChangeView()}><FaColumns /></button>
                  <SwalWarn show={swalWarnOpen} message={alertMessage} onClose={() => setSwalWarnOpen(false)} /> 
                  <SwalError show={swalErrorOpen} message={alertMessage} onClose={() => setSwalErrorOpen(false)} /> 
                  <ConfirmGoods show={buypopup} onHide={() => setBuyPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount}/>  
                  <HoldPopup show={holdpopup} onHide={() => setHoldPopUp(false)} diamondselected={diamondselected} symbol={symbol} conversionrate={conversionrate} totalinrcar={buypopupcinr} totalourprice={buypopupctotal} diamondcount={diamondcount}/>  
              </div>
          </div>
          <div className="card-body p-2">
            <TableContainer className="shadow-none custom-scrollbar" component={Paper}>
                <Table aria-label="collapsible table" className="table table-hover mb-0 custom_datatable">
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={StockAPI.length}
                        symbol={symbol}
                        language={language}
                    />
                        {/* <TableRow>
                            <TableCell />
                            <TableCell>StockID</TableCell>
                            <TableCell align="center">Location</TableCell>
                            <TableCell align="center">Media</TableCell>
                            <TableCell align="center">Shape</TableCell>
                            <TableCell align="center">Carat</TableCell>
                            <TableCell align="center">Color</TableCell>
                            <TableCell align="center">Clarity</TableCell>
                            <TableCell align="center">Cut</TableCell>
                            <TableCell align="center">Pol.</TableCell>
                            <TableCell align="center">Symm.</TableCell>
                            <TableCell align="center">Flou.</TableCell>
                            <TableCell align="center">Lab</TableCell>
                            <TableCell align="center">INR/CT</TableCell>
                            <TableCell align="center">Total</TableCell>
                            <TableCell align="center">Disc%</TableCell>
                            <TableCell align="center">Ratio</TableCell>
                            <TableCell align="center">Meas.</TableCell>
                            <TableCell align="center">Table</TableCell>
                            <TableCell align="center">Depth</TableCell>
                            <TableCell align="center">Certificate</TableCell>
                        </TableRow> */}
                        {StockAPI.length > 0 && 
                    <TableBody>
                        { stableSort(StockAPI, getComparator(order, orderBy)).slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                            ).map((row,index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return(
                                    <Row key={row.id} row={row} isItemSelected={isItemSelected} selected={isItemSelected} handleClick={handleClick} labelId={labelId} conversionrate={conversionrate} symbol={symbol} wishlistdata={wishlistdata} setUpdateWishlist={setUpdateWishlist} language={language} totalourprice={buypopupctotal} totalinrcar={buypopupcinr} diamondselected={diamondselected} buypopup={buypopup} BuyDiamond={BuyDiamond} HoldDiamond={HoldDiamond}/>
                                )
                            }
                        )}
                    </TableBody> ||
                        <div className="col-12 text-center py-5 font-weight-bold">Please Wait <span className="ml-2 spinner spinner-primary"></span></div > }
                </Table>
            </TableContainer>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={StockAPI.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
          </div >
        </div >
    );
};
